import { useDispatch } from "react-redux";
import { useGallery } from "../../../State/Context/GalleryContext";
import { useMemo, useState } from "react";
import { Draggable } from "../../../Components/Draggable/Draggable";
import { GalleryItem } from "../../../Types/GalleryItem";
import { updateGalleryItems } from "../../../State/Actions/GalleryActions";

type GalleryOrderSetManagerProps = {
    items: GalleryItem[];
    title: string;
    type: "collection" | "category";
};

const GalleryOrderSetManager: React.FC<GalleryOrderSetManagerProps> = ({
    items,
    title,
    type,
}) => {
    const dispatch = useDispatch<any>();

    const [saveOrder, setSaveOrder] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);

    const callback = (items: GalleryItem[]) => {
        setDragEnabled(false);

        const updatedItems = items.map((item, index) => ({
            ...item,
            categoryOrder: type === "category" ? index : item.categoryOrder,
            collectionOrder:
                type === "collection" ? index : item.collectionOrder,
        }));

        dispatch(updateGalleryItems(updatedItems));
    };
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-x-5">
                <p className="font-bold">{title}</p>
                <label htmlFor={`${title}_${type}_drag`}>
                    <input
                        checked={dragEnabled}
                        onChange={(e) => setDragEnabled(e.target.checked)}
                        className="px-5"
                        type="checkbox"
                        id={`${title}_${type}_drag`}
                    />
                    &nbsp;Reorder
                </label>
                <button
                    className={`${!dragEnabled && "hidden"}`}
                    onClick={() => setSaveOrder(true)}
                >
                    Volgorde opslaan
                </button>
            </div>
            <div className={`${!dragEnabled && "hidden"}`}>
                <Draggable
                    dragEnabled={dragEnabled}
                    triggerCallback={saveOrder}
                    setTriggerCallback={setSaveOrder}
                    callback={callback}
                    items={items}
                    renderItem={(item, i) => <div key={i}>{item.title}</div>}
                />
            </div>
        </div>
    );
};

export const GalleryOrderManager: React.FC = () => {
    const { categories, collections, galleryItems } = useGallery();

    const { groupedByCategory, groupedByCollection } = useMemo(() => {
        // Create objects to hold the grouped items
        let itemsByCollection: any = {};
        let itemsByCategory: any = {};

        // Initialize the objects with empty arrays
        collections.forEach((collection) => {
            itemsByCollection[collection.collectionId] = [];
        });

        categories.forEach((category) => {
            itemsByCategory[category.categoryId] = [];
        });

        // Group items by collectionId and categoryId
        galleryItems.forEach((item) => {
            if (item.collectionId && itemsByCollection[item.collectionId]) {
                itemsByCollection[item.collectionId].push(item);
            }
            if (item.categoryId && itemsByCategory[item.categoryId]) {
                itemsByCategory[item.categoryId].push(item);
            }
        });

        // Convert the grouped objects to arrays of arrays
        let groupedByCollection: GalleryItem[][] =
            Object.values(itemsByCollection);
        let groupedByCategory: GalleryItem[][] = Object.values(itemsByCategory);

        groupedByCategory = groupedByCategory.filter((x) => x.length > 0).map(x => x.sort((a, b) => a.categoryOrder - b.categoryOrder));
        groupedByCollection = groupedByCollection.filter((x) => x.length > 0).map(x => x.sort((a, b) => a.collectionOrder - b.collectionOrder));

        return { groupedByCollection, groupedByCategory };
    }, [galleryItems, collections, categories]);

    return (
        <div className="flex flex-col gap-10">
            {groupedByCollection.map((group, i) => (
                <GalleryOrderSetManager
                    items={group}
                    key={i}
                    title={
                        collections.find(
                            (x) => x.collectionId === group[0].collectionId
                        )?.title ?? ""
                    }
                    type="collection"
                />
            ))}
            {groupedByCategory.map((group, i) => (
                <GalleryOrderSetManager
                    items={group}
                    key={i}
                    title={
                        categories.find(
                            (x) => x.categoryId === group[0].categoryId
                        )?.title ?? ""
                    }
                    type="category"
                />
            ))}
        </div>
    );
};
