import { useExhibitions } from "../../../State/Context/ExhibitionsContext";
import { ExhibitionForm } from "../Forms/ExhibitionsForm";

export const ExhibitionsManager: React.FC = () => {
    const { exhibitions } = useExhibitions();

    return (
        <div className="flex flex-row flex-wrap gap-10">
            {exhibitions.map((exhibition) => (
                <ExhibitionForm exhibition={exhibition} key={exhibition.id} />
            ))}
            <ExhibitionForm />
        </div>
    );
};
