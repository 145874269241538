import React, { useEffect, useState } from "react";
import { ImagePicker } from "../../../Components/ImagePicker/ImagePicker";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { CreateLinkItem, LinkItem, UpdateLinkItem } from "../../../Types/LinkItem";
import { createLinkItem, deleteLink, updateLink } from "../../../State/Actions/LInksActions";

type LinksFormProps = {
    item?: LinkItem;
};
export const LinksForm: React.FC<LinksFormProps> = ({ item }) => {
    const dispatch = useDispatch<any>();

    const [id] = useState(item?.id);
    const [image, setImage] = useState<File | undefined>(undefined);
    const [url, setUrl] = useState<string | undefined>(item?.url);

    useEffect(() => {
        if (!item) return;
        if (!item.image) return;
        toFile(item.image);
    }, [item, setImage]);

    const toFile = async (imagePath: string): Promise<void> => {
        if (!imagePath) return;

        const response = await fetch(imagePath);
        const blob = await response.blob();

        const extension = blob.type.split("/").pop();

        let mimetype = "";

        switch (extension) {
            case "png":
                mimetype = "image/png";
                break;
            case "jpg":
                mimetype = "image/jpeg";
                break;
            case "jpeg":
                mimetype = "image/jpeg";
                break;
            case "gif":
                mimetype = "image/gif";
                break;
            case "webp":
                mimetype = "image/webp";
                break;
        }

        const file = new File([blob], `image.${extension}`, { type: mimetype });
        if (file) {
            setImage(file);
        }
    };

    const reset = () => {
        setImage(undefined);
        setUrl("");
    };

    const create = () => {
        if (!image) return;

        const itemToCreate: CreateLinkItem = {
            image,
            url,
        };
        dispatch(createLinkItem(itemToCreate));
        reset();
    };

    const update = () => {
        if (!id || !image) return;

        const itemToUpdate: UpdateLinkItem = {
            id,
            image,
            url,
        };

        dispatch(updateLink(itemToUpdate));
    };

    const deleteIt = () => {
        if (!id) return;

        confirmAlert({
            title: `Delete`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deleteLink(id)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="flex flex-row gap-5 bg-site-accent bg-opacity-10 rounded-md p-2">
            <div className="flex flex-col gap-5">
                <p className="text-xs">Url</p>
                <input
                    name="urlInput"
                    placeholder="url"
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                ></input>
                <ImagePicker
                    selectedImage={image}
                    setSelectedImage={setImage}
                />
            </div>
            {id ? (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={update}
                    >
                        Update
                    </button>
                    <button className="p-3" onClick={deleteIt}>
                        <FontAwesomeIcon icon={faTrash} color="darkred" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={create}
                    >
                        Create
                    </button>
                    <button className="p-3" onClick={() => reset()}>
                        <FontAwesomeIcon icon={faUndo} />
                    </button>
                </div>
            )}
        </div>
    );
};