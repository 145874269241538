import { ReactNode, createContext, useContext, useMemo } from "react";
import { ExhibitionItem } from "../../Types/ExhibitionItem";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";

export type CountByTime = {
    time: string;
    count: number;
};

interface ExhibitionsContextType {
    exhibitions: ExhibitionItem[];
    soloExhibitions: ExhibitionItem[];
    groupExhibitions: ExhibitionItem[];
    loading: boolean;
    exhibitionsCount: CountByTime[];
}

const ExhibitionsContext = createContext<ExhibitionsContextType | undefined>(
    undefined
);

export const ExhibitionsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const exhibitions = useSelector((state: RootState) =>
        state.exhibitions.exhibitions.sort((a, b) => a.order - b.order)
    );
    const loading = useSelector(
        (state: RootState) => state.exhibitions.loading
    );
    const soloExhibitions = useMemo(
        () => exhibitions.filter((x) => x.exhibitionTypeId === 1),
        [exhibitions]
    );
    const groupExhibitions = useMemo(
        () => exhibitions.filter((x) => x.exhibitionTypeId === 2),
        [exhibitions]
    );
    const exhibitionsCount: CountByTime[] = useMemo(() => {
        const groupedByYear = exhibitions.reduce((acc: any, item) => {
            const { time } = item;
            if (!acc[time]) {
                acc[time] = [];
            }
            acc[time].push(item);
            return acc;
        }, {});

        return Object.keys(groupedByYear).map((year) => ({
            time: year,
            count: groupedByYear[year].length,
        }));
    }, [exhibitions]);

    const providerContext: ExhibitionsContextType = {
        exhibitions,
        soloExhibitions,
        groupExhibitions,
        loading,
        exhibitionsCount,
    };

    return (
        <ExhibitionsContext.Provider value={providerContext}>
            {children}
        </ExhibitionsContext.Provider>
    );
};

export const useExhibitions = (): ExhibitionsContextType => {
    const context = useContext(ExhibitionsContext);
    if (!context) {
        throw new Error(
            "useExhibitions must be used with an ExhibitionsProvider"
        );
    }
    return context;
};
