import React from "react";
import { usePosts } from "../../../State/Context/PostsContext";
import { PostForm } from "../Forms/PostForm";

export const PostManager: React.FC = () => {
    const { posts } = usePosts();

    return (
        <div className="flex flex-row flex-wrap gap-10">
            {posts.map((post) => (
                <PostForm post={post} key={post.id} />
            ))}
            <PostForm />
        </div>
    );
};
