import supabase from "./client";
import {
    CreateGalleryItem,
    GalleryItem,
    UpdateGalleryItem,
} from "../../Types/GalleryItem";
import { CreateCategory, GalleryCategory } from "../../Types/GalleryCategory";
import {
    CreateCollection,
    GalleryCollection,
} from "../../Types/GalleryCollection";
import { uploadImage } from "./images";

const GALLERY_TABLE = "Gallery";
const COLLECTIONS_TABLE = "Collections";
const CATEGORIES_TABLE = "Categories";

export type GetEntireGalleryType = {
    galleryItems: GalleryItem[];
    categories: GalleryCategory[];
    collections: GalleryCollection[];
    errors?: string[];
};

export type GetGalleryType = {
    galleryItems: GalleryItem[];
    error?: string;
};

export type GetCollectionsType = {
    collections: GalleryCollection[];
    error?: string;
};
export type GetCategoriesType = {
    categories: GalleryCategory[];
    error?: string;
};

export const getEntireGallery = async (): Promise<GetEntireGalleryType> => {
    const galleryResult = await getGallery();
    const categoriesResult = await getCategories();
    const collectionsResult = await getCollections();

    if (
        galleryResult.error ||
        categoriesResult.error ||
        collectionsResult.error
    ) {
        return {
            galleryItems: [],
            categories: [],
            collections: [],
            errors: [
                galleryResult.error ?? "",
                categoriesResult.error ?? "",
                collectionsResult.error ?? "",
            ],
        };
    }

    return {
        galleryItems: galleryResult.galleryItems,
        categories: categoriesResult.categories,
        collections: collectionsResult.collections,
    };
};

const getGallery = async (): Promise<GetGalleryType> => {
    const { data, error } = await supabase.from(GALLERY_TABLE).select();

    if (error) {
        return {
            galleryItems: [],
            error: error.message,
        };
    }

    return {
        galleryItems: data.map((x) => ({
            id: x.id,
            title: x.title,
            categoryId: x.categoryId ?? undefined,
            collectionId: x.collectionId ?? undefined,
            dateUploaded: x.dateUploaded,
            image: x.image,
            size: x.size ?? undefined,
            collectionOrder: x.collectionOrder,
            categoryOrder: x.categoryOrder,
        })),
        error: undefined,
    };
};

const getCollections = async (): Promise<GetCollectionsType> => {
    const { data, error } = await supabase.from(COLLECTIONS_TABLE).select();

    if (error) {
        return {
            collections: [],
            error: error.message,
        };
    }

    return {
        collections: data.map((x) => ({
            collectionId: x.id,
            title: x.title,
            order: x.order,
        })),
        error: undefined,
    };
};

const getCategories = async (): Promise<GetCategoriesType> => {
    const { data, error } = await supabase.from(CATEGORIES_TABLE).select();

    if (error) {
        return {
            categories: [],
            error: error.message,
        };
    }

    return {
        categories: data.map((x) => ({
            categoryId: x.id,
            title: x.title,
            order: x.order,
        })),
        error: undefined,
    };
};

export type CreateCategoryReponse = {
    category: GalleryCategory;
    error?: string;
};

export const createCategory = async (
    request: CreateCategory
): Promise<CreateCategoryReponse> => {
    const { data, error } = await supabase
        .from(CATEGORIES_TABLE)
        .insert({
            title: request.title,
            order: request.order,
        })
        .select()
        .single();

    if (error) {
        return {
            category: {
                categoryId: -1,
                title: "error",
                order: 0,
            },
            error: error.message,
        };
    }

    return {
        category: {
            categoryId: data.id,
            title: data.title,
            order: data.order,
        },
    };
};

export type UpdateCategoryResponse = {
    category: GalleryCategory;
    error?: string;
};

export const updateCategory = async (
    category: GalleryCategory
): Promise<UpdateCategoryResponse> => {
    const { error } = await supabase
        .from(CATEGORIES_TABLE)
        .update({
            title: category.title,
        })
        .eq("id", category.categoryId);
    if (error) {
        return {
            category,
            error: error.message,
        };
    }

    return {
        category,
    };
};

export type UpdateCategoriesResponse = {
    categories: GalleryCategory[];
    error?: string;
};

export const updateCategories = async (
    categories: GalleryCategory[]
): Promise<UpdateCategoriesResponse> => {
    let updatedCategories: GalleryCategory[] = [];

    let errorMessage = "";

    categories.forEach(async (category) => {
        const { data, error } = await supabase
            .from(CATEGORIES_TABLE)
            .update({
                order: category.order,
            })
            .eq("id", category.categoryId)
            .select()
            .single();

        if (error) {
            errorMessage += error.message;
        } else {
            updatedCategories = [
                ...updatedCategories,
                {
                    categoryId: data.id,
                    title: data.title,
                    order: data.order,
                },
            ];
        }
    });

    return {
        categories,
        error: errorMessage || undefined,
    };
};

export type DeleteCategoryResponse = {
    categoryId: number;
    error?: string;
};

export const deleteCategory = async (
    categoryId: number
): Promise<DeleteCategoryResponse> => {
    const { error } = await supabase
        .from(CATEGORIES_TABLE)
        .delete()
        .eq("id", categoryId);

    if (error) {
        return {
            categoryId,
            error: error.message,
        };
    }

    return {
        categoryId,
    };
};

export type CreateCollectionReponse = {
    collection: GalleryCollection;
    error?: string;
};

export const createCollection = async (
    request: CreateCollection
): Promise<CreateCollectionReponse> => {
    const { data, error } = await supabase
        .from(COLLECTIONS_TABLE)
        .insert({
            title: request.title,
            order: request.order,
        })
        .select()
        .single();

    if (error) {
        return {
            collection: {
                collectionId: -1,
                title: "error",
                order: 0,
            },
            error: error.message,
        };
    }

    return {
        collection: {
            collectionId: data.id,
            title: data.title,
            order: data.order,
        },
    };
};

export type UpdateCollectionResponse = {
    collection: GalleryCollection;
    error?: string;
};

export const updateCollection = async (
    collection: GalleryCollection
): Promise<UpdateCollectionResponse> => {
    const { error } = await supabase
        .from(COLLECTIONS_TABLE)
        .update({
            title: collection.title,
        })
        .eq("id", collection.collectionId);
    if (error) {
        return {
            collection,
            error: error.message,
        };
    }

    return {
        collection,
    };
};

export type UpdateCollectionsResponse = {
    collections: GalleryCollection[];
    error?: string;
};

export const updateCollections = async (
    collections: GalleryCollection[]
): Promise<UpdateCollectionsResponse> => {
    let updatedCollections: GalleryCollection[] = [];

    let errorMessage = "";

    collections.forEach(async (collection) => {
        const { data, error } = await supabase
            .from(COLLECTIONS_TABLE)
            .update({
                order: collection.order,
            })
            .eq("id", collection.collectionId)
            .select()
            .single();

        if (error) {
            errorMessage += error.message;
        } else {
            updatedCollections = [
                ...updatedCollections,
                {
                    collectionId: data.id,
                    title: data.title,
                    order: data.order,
                },
            ];
        }
    });

    return {
        collections,
        error: errorMessage || undefined,
    };
};

export type DeleteCollectionResponse = {
    collectionId: number;
    error?: string;
};

export const deleteCollection = async (
    collectionId: number
): Promise<DeleteCollectionResponse> => {
    const { error } = await supabase
        .from(COLLECTIONS_TABLE)
        .delete()
        .eq("id", collectionId);

    if (error) {
        return {
            collectionId,
            error: error.message,
        };
    }

    return {
        collectionId,
    };
};

export type CreateGalleryItemResponse =
    | {
          galleryItem: GalleryItem;
          error?: undefined;
      }
    | { error: string };

export const createGalleryItem = async (
    request: CreateGalleryItem
): Promise<CreateGalleryItemResponse> => {
    const imageUploadResponse = await uploadImage(request.image, "works");

    if (!imageUploadResponse.success) {
        return {
            error: imageUploadResponse.error,
        };
    }

    const { data, error } = await supabase
        .from(GALLERY_TABLE)
        .insert({
            ...request,
            image: imageUploadResponse.path,
        })
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        galleryItem: {
            id: data.id,
            title: data.title,
            categoryId: data.categoryId ?? undefined,
            collectionId: data.collectionId ?? undefined,
            image: data.image,
            size: data.size ?? undefined,
            dateUploaded: data.dateUploaded,
            collectionOrder: data.collectionOrder,
            categoryOrder: data.categoryOrder,
        },
    };
};

export type UpdateGalleryItemResponse =
    | {
          galleryItem: GalleryItem;
          error?: undefined;
      }
    | { error: string };

export const updateGalleryItem = async (
    galleryItem: UpdateGalleryItem
): Promise<UpdateGalleryItemResponse> => {
    const imageUploadResponse = await uploadImage(galleryItem.image, "works");

    if (!imageUploadResponse.success) {
        return {
            error: imageUploadResponse.error,
        };
    }

    const { data, error } = await supabase
        .from(GALLERY_TABLE)
        .update({
            ...galleryItem,
            categoryId: galleryItem.categoryId || null,
            collectionId: galleryItem.collectionId || null,
            size: galleryItem.size || null,
            image: imageUploadResponse.path,
        })
        .eq("id", galleryItem.id)
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        galleryItem: {
            id: data.id,
            title: data.title,
            categoryId: data.categoryId ?? undefined,
            collectionId: data.collectionId ?? undefined,
            image: data.image,
            size: data.size ?? undefined,
            dateUploaded: data.dateUploaded,
            collectionOrder: data.collectionOrder,
            categoryOrder: data.categoryOrder,
        },
    };
};

export type UpdateGalleryItemsResponse = {
    galleryItems: GalleryItem[];
    error?: string;
};

export const updateGalleryItems = async (
    galleryItems: GalleryItem[]
): Promise<UpdateGalleryItemsResponse> => {
    let updatedGalleryItems: GalleryItem[] = [];

    let errorMessage = "";

    galleryItems.forEach(async (galleryItem) => {
        const { data, error } = await supabase
            .from(GALLERY_TABLE)
            .update({
                categoryOrder: galleryItem.categoryOrder,
                collectionOrder: galleryItem.collectionOrder,
            })
            .eq("id", galleryItem.id)
            .select()
            .single();

        if (error) {
            errorMessage += error.message;
        } else {
            updatedGalleryItems = [
                ...updatedGalleryItems,
                {
                    id: data.id,
                    title: data.title,
                    categoryId: data.categoryId ?? undefined,
                    collectionId: data.collectionId ?? undefined,
                    image: data.image,
                    size: data.size ?? undefined,
                    dateUploaded: data.dateUploaded,
                    collectionOrder: data.collectionOrder,
                    categoryOrder: data.categoryOrder,
                },
            ];
        }
    });

    return {
        galleryItems,
        error: errorMessage || undefined,
    };
};

export type DeleteGalleryItemResponse = {
    galleryItemId: number;
    error?: string;
};

export const deleteGalleryItem = async (
    galleryItemId: number
): Promise<DeleteGalleryItemResponse> => {
    const { error } = await supabase
        .from(GALLERY_TABLE)
        .delete()
        .eq("id", galleryItemId);

    if (error) {
        return {
            galleryItemId,
            error: error.message,
        };
    }

    return {
        galleryItemId,
    };
};
