import React, { ReactNode } from "react";
import { GalleryProvider } from "./GalleryContext";
import { PostsProvider } from "./PostsContext";
import { ExhibitionsProvider } from "./ExhibitionsContext";
import { AdminProvider } from "./AdminContext";
import { SiteContentProvider } from "./SiteContentContext";
import { SettingsProvider } from "./SettingsContext";
import { LinksProvider } from "./LinksContext";

export const RootProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    return (
        <SettingsProvider>
            <AdminProvider>
                <SiteContentProvider>
                    <LinksProvider>
                        <GalleryProvider>
                            <PostsProvider>
                                <ExhibitionsProvider>
                                    <>{children}</>
                                </ExhibitionsProvider>
                            </PostsProvider>
                        </GalleryProvider>
                    </LinksProvider>
                </SiteContentProvider>
            </AdminProvider>
        </SettingsProvider>
    );
};
