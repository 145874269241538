import { useCallback, useEffect, useState } from "react";

const useTimeResetCounter = ({
    activationCount,
    timeout,
    callback
}: {
    activationCount: number,
    timeout: number,
    callback: () => void,
}) => {
    const [count, setCount] = useState<number>(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

    const updateCounter = useCallback(() => {
        if (!timer) {
            const newTimer = setTimeout(() => {
                setCount(0);
                setTimer(undefined);
            }, timeout);
            setTimer(newTimer);
        }

        setCount(prev => prev + 1);
    }, [timer, setTimer, setCount, timeout]);

    useEffect(() => {
        if (count >= activationCount) {
            clearTimeout(timer);
            setTimer(undefined);
            setCount(0);
            callback();
        }
    }, [count, activationCount, timer, setTimer, setCount, callback]);

    return updateCounter;
}

export default useTimeResetCounter;