import supabase from "./client";
import { CreatePost, PostItem, UpdatePost } from "../../Types/PostItem";
import { uploadImage } from "./images";

const POSTS_TABLE = "Posts";

export type GetPostsType = {
    posts: PostItem[];
    error?: string;
};

export const getPosts = async (): Promise<GetPostsType> => {
    const { data, error } = await supabase.from(POSTS_TABLE).select();

    if (error) {
        return {
            posts: [],
            error: error.message,
        };
    }

    return {
        posts: data.map((x) => ({
            id: x.id,
            title: x.title,
            image: x.image ?? undefined,
            location: x.location,
            date: {
                start: x.startDate,
                end: x.endDate ?? undefined,
            },
            time: !x.startTime
                ? undefined
                : {
                      start: x.startTime,
                      end: x.endTime ?? undefined,
                  },
            description: x.description ?? undefined,
        })),
    };
};

export type CreatePostResponse =
    | {
          post: PostItem;
          error?: undefined;
      }
    | { error: string };

export const createPost = async (
    request: CreatePost
): Promise<CreatePostResponse> => {
    let imagePath = null;

    if (request.image) {
        const imageUploadResponse = await uploadImage(request.image, "posts");

        if (!imageUploadResponse.success) {
            return {
                error: imageUploadResponse.error,
            };
        }
        imagePath = imageUploadResponse.path;
    }

    const { data, error } = await supabase
        .from(POSTS_TABLE)
        .insert({
            title: request.title,
            location: request.location,
            startDate: request.date.start,
            endDate: request.date.end,
            startTime: request.time?.start,
            endTime: request.time?.start ? request.time.end : undefined,
            image: imagePath,
            description: request.description,
        })
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        post: {
            id: data.id,
            title: data.title,
            location: data.location,
            date: {
                start: data.startDate,
                end: data.endDate ?? undefined,
            },
            time: data.startTime
                ? {
                      start: data.startTime,
                      end: data.endTime ?? undefined,
                  }
                : undefined,
            image: data.image ?? undefined,
            description: data.description ?? undefined,
        },
    };
};

export type UpdatePostResponse =
    | {
          post: PostItem;
          error?: undefined;
      }
    | { error: string };

export const updatePost = async (
    post: UpdatePost
): Promise<UpdatePostResponse> => {
    let imagePath = null;

    if (post.image) {
        const imageUploadResponse = await uploadImage(post.image, "posts");

        if (!imageUploadResponse.success) {
            return {
                error: imageUploadResponse.error,
            };
        }
        imagePath = imageUploadResponse.path;
    }

    const { data, error } = await supabase
        .from(POSTS_TABLE)
        .update({
            title: post.title,
            location: post.location,
            startDate: post.date.start,
            endDate: post.date.end,
            startTime: post.time?.start,
            endTime: post.time?.start ? post.time.end : undefined,
            image: imagePath,
            description: post.description,
        })
        .eq("id", post.id)
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        post: {
            id: data.id,
            title: data.title,
            location: data.location,
            date: {
                start: data.startDate,
                end: data.endDate ?? undefined,
            },
            time: data.startTime
                ? {
                      start: data.startTime,
                      end: data.endTime ?? undefined,
                  }
                : undefined,
            image: data.image ?? undefined,
            description: data.description ?? undefined,
        },
    };
};

export type DeletePostResponse = {
    postId: number;
    error?: string;
};

export const deletePost = async (
    postId: number
): Promise<DeletePostResponse> => {
    const { error } = await supabase
        .from(POSTS_TABLE)
        .delete()
        .eq("id", postId);

    if (error) {
        return {
            postId,
            error: error.message,
        };
    }

    return {
        postId,
    };
};
