import { ExhibitionItem } from "../../Types/ExhibitionItem";
import {
    GET_EXHIBITIONS,
    GET_EXHIBITIONS_SUCCESS,
    GET_EXHIBITIONS_FAILURE,
    ExhibitionActionTypes,
    CREATE_EXHIBITION_SUCCESS,
    DELETE_EXHIBITION_SUCCESS,
    UPDATE_EXHIBITION_SUCCESS,
    UPDATE_EXHIBITIONS_SUCCESS,
} from "../Actions/ActionTypes/Exhibitions";

export type ExhibitionsState = {
    exhibitions: ExhibitionItem[];
    loading: boolean;
    error?: string;
};

const initialState: ExhibitionsState = {
    exhibitions: [],
    loading: false,
    error: undefined,
};

const exhibitionsReducer = (
    state = initialState,
    action: ExhibitionActionTypes
): ExhibitionsState => {
    switch (action.type) {
        case GET_EXHIBITIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_EXHIBITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                exhibitions: action.payload.exhibitions,
            };
        case GET_EXHIBITIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CREATE_EXHIBITION_SUCCESS:
            return {
                ...state,
                exhibitions: [...state.exhibitions, action.payload.exhibition],
            };

        case UPDATE_EXHIBITION_SUCCESS:
            return {
                ...state,
                exhibitions: [
                    ...state.exhibitions.filter(
                        (x) => x.id !== action.payload.exhibition.id
                    ),
                    action.payload.exhibition,
                ],
            };

        case UPDATE_EXHIBITIONS_SUCCESS:
            return {
                ...state,
                exhibitions: [
                    ...state.exhibitions.filter(
                        (x) =>
                            !action.payload.exhibitions.find(
                                (y) => y.id === x.id
                            )
                    ),
                    ...action.payload.exhibitions,
                ],
            };

        case DELETE_EXHIBITION_SUCCESS:
            return {
                ...state,
                exhibitions: [
                    ...state.exhibitions.filter(
                        (x) => x.id !== action.payload.exhibitionId
                    ),
                ],
            };

        default:
            return {
                ...state,
            };
    }
};

export default exhibitionsReducer;
