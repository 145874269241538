import React, { useEffect, useState } from "react";
import { ImagePicker } from "../../../Components/ImagePicker/ImagePicker";
import { useGallery } from "../../../State/Context/GalleryContext";
import {
    CreateGalleryItem,
    GalleryItem,
    UpdateGalleryItem,
} from "../../../Types/GalleryItem";
import { useDispatch } from "react-redux";
import {
    createGalleryItem,
    deleteGalleryItem,
    updateGalleryItem,
} from "../../../State/Actions/GalleryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";

type GalleryFormProps = {
    item?: GalleryItem;
};

export const GalleryForm: React.FC<GalleryFormProps> = ({ item }) => {
    const dispatch = useDispatch<any>();

    const [id] = useState(item?.id);
    const [title, setTitle] = useState(item?.title ?? "");
    const [size, setSize] = useState(item?.size ?? "");
    const [categoryId, setCategoryId] = useState(item?.categoryId ?? 0);
    const [collectionId, setCollectionId] = useState(item?.collectionId ?? 0);
    const [image, setImage] = useState<File | undefined>(undefined);

    const { categories, collections, countByCategories, countByCollections } =
        useGallery();

    useEffect(() => {
        if (!item) return;
        if (!item.image) return;
        toFile(item.image);
    }, [item, setImage]);

    const toFile = async (imagePath: string): Promise<void> => {
        if (!imagePath) return;

        const response = await fetch(imagePath);
        const blob = await response.blob();

        const extension = blob.type.split("/").pop();

        let mimetype = "";

        switch (extension) {
            case "png":
                mimetype = "image/png";
                break;
            case "jpg":
                mimetype = "image/jpeg";
                break;
            case "jpeg":
                mimetype = "image/jpeg";
                break;
            case "gif":
                mimetype = "image/gif";
                break;
            case "webp":
                mimetype = "image/webp";
                break;
        }

        const file = new File([blob], `image.${extension}`, { type: mimetype });
        if (file) {
            setImage(file);
        }
    };

    const reset = () => {
        setTitle("");
        setSize("");
        setCategoryId(0);
        setCollectionId(0);
        setImage(undefined);
    };

    const create = () => {
        if (!title || !image) return;

        const categoryOrder = categoryId
            ? countByCategories.find((x) => x.categoryId === categoryId)
                  ?.count ?? 0
            : 0;

        const collectionOrder = collectionId
            ? countByCollections.find((x) => x.collectionId === collectionId)
                  ?.count ?? 0
            : 0;

        const itemToCreate: CreateGalleryItem = {
            title,
            categoryId: !categoryId ? undefined : categoryId,
            collectionId: !collectionId ? undefined : collectionId,
            image,
            size,
            categoryOrder,
            collectionOrder,
        };
        dispatch(createGalleryItem(itemToCreate));
        reset();
    };

    const update = () => {
        if (!id || !title || !image) return;

        const itemToUpdate: UpdateGalleryItem = {
            id,
            title,
            categoryId: !categoryId ? undefined : categoryId,
            collectionId: !collectionId ? undefined : collectionId,
            image,
            size,
            categoryOrder: item!.categoryOrder,
            collectionOrder: item!.collectionOrder,
        };

        dispatch(updateGalleryItem(itemToUpdate));
    };

    const deleteIt = () => {
        if (!id) return;

        confirmAlert({
            title: `Delete ${title}`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deleteGalleryItem(id)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="flex flex-row gap-5 bg-site-accent bg-opacity-10 rounded-md p-2">
            <div className="flex flex-col gap-5">
                <p className="text-xs">Title</p>
                <input
                    name="titleInput"
                    placeholder="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                ></input>

                <p className="text-xs">Size</p>
                <input
                    name="sizeInput"
                    placeholder="size"
                    type="text"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                ></input>

                <p className="text-xs">Category</p>
                <select
                    name="categorySelect"
                    value={categoryId}
                    onChange={(e) => setCategoryId(parseInt(e.target.value))}
                >
                    <option value={0}>None</option>
                    {categories.map((category) => (
                        <option
                            key={category.categoryId}
                            value={category.categoryId}
                        >
                            {category.title}
                        </option>
                    ))}
                </select>

                <p className="text-xs">Collection</p>
                <select
                    name="collectionSelect"
                    value={collectionId}
                    onChange={(e) => setCollectionId(parseInt(e.target.value))}
                >
                    <option value={0}>None</option>
                    {collections.map((collection) => (
                        <option
                            key={collection.collectionId}
                            value={collection.collectionId}
                        >
                            {collection.title}
                        </option>
                    ))}
                </select>
                <ImagePicker
                    selectedImage={image}
                    setSelectedImage={setImage}
                />
            </div>
            {id ? (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={update}
                    >
                        Update
                    </button>
                    <button className="p-3" onClick={deleteIt}>
                        <FontAwesomeIcon icon={faTrash} color="darkred" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={create}
                    >
                        Create
                    </button>
                    <button className="p-3" onClick={() => reset()}>
                        <FontAwesomeIcon icon={faUndo} />
                    </button>
                </div>
            )}
        </div>
    );
};
