import React, { useEffect, useState } from "react";
import { ImagePicker } from "../../../Components/ImagePicker/ImagePicker";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { PostItem } from "../../../Types/PostItem";
import {
    createPost,
    updatePost,
    deletePost,
} from "../../../State/Actions/PostActions";

type PostFormProps = {
    post?: PostItem;
};
export const PostForm: React.FC<PostFormProps> = ({ post }) => {
    const dispatch = useDispatch<any>();

    const [id] = useState(post?.id);
    const [title, setTitle] = useState(post?.title ?? "");
    const [description, setDescription] = useState(post?.description ?? "");
    const [location, setLocation] = useState(post?.location ?? "");
    const [startDate, setStartDate] = useState(post?.date.start ?? "");
    const [endDate, setEndDate] = useState(post?.date.end ?? "");
    const [startTime, setStartTime] = useState(post?.time?.start ?? "");
    const [endTime, setEndTime] = useState(post?.time?.end ?? "");
    const [image, setImage] = useState<File | undefined>(undefined);

    useEffect(() => {
        if (!post) return;
        if (!post.image) return;
        toFile(post.image);
    }, [post, setImage]);

    const toFile = async (imagePath: string): Promise<void> => {
        if (!imagePath) return;

        const response = await fetch(imagePath);
        const blob = await response.blob();

        const extension = blob.type.split("/").pop();

        let mimetype = "";

        switch (extension) {
            case "png":
                mimetype = "image/png";
                break;
            case "jpg":
                mimetype = "image/jpeg";
                break;
            case "jpeg":
                mimetype = "image/jpeg";
                break;
            case "gif":
                mimetype = "image/gif";
                break;
            case "webp":
                mimetype = "image/webp";
                break;
        }

        const file = new File([blob], `image.${extension}`, { type: mimetype });
        if (file) {
            setImage(file);
        }
    };

    const reset = () => {
        setTitle("");
        setDescription("");
        setLocation("");
        setStartDate("");
        setEndDate("");
        setStartTime("");
        setEndTime("");
        setImage(undefined);
    };

    const create = () => {
        if (!title || !location || !startDate) return;

        dispatch(
            createPost({
                title,
                description,
                location,
                image,
                date: {
                    start: startDate,
                    end: endDate,
                },
                time: startTime
                    ? {
                          start: startTime,
                          end: endTime,
                      }
                    : undefined,
            })
        );

        reset();
    };

    const update = () => {
        if (!id || !title || !location || !startDate) return;

        dispatch(
            updatePost({
                id,
                description,
                title,
                location,
                image,
                date: {
                    start: startDate,
                    end: endDate,
                },
                time: startTime
                    ? {
                          start: startTime,
                          end: endTime,
                      }
                    : undefined,
            })
        );
    };

    const deleteIt = () => {
        if (!id) return;

        confirmAlert({
            title: `Delete ${title}`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deletePost(id)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="flex flex-row gap-5 bg-site-accent bg-opacity-10 rounded-md p-2">
            <div className="flex flex-col gap-5">
                <p className="text-xs">Title</p>
                <input
                    name="titleInput"
                    placeholder="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                ></input>

                <p className="text-xs">Description</p>
                <textarea
                    placeholder="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>

                <p className="text-xs">Location</p>
                <input
                    name="locationInput"
                    placeholder="location"
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                ></input>

                <p className="text-xs">Start time</p>
                <div className="flex flex-row flex-nowrap">
                    <input
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                    <button onClick={() => setStartTime("")} className="px-3">
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>

                <p className="text-xs">End time</p>
                <div className="flex flex-row flex-nowrap">
                    <input
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    />
                    <button onClick={() => setEndTime("")} className="px-3">
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>

                <p className="text-xs">Start date</p>
                <div className="flex flex-row flex-nowrap">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <button onClick={() => setStartDate("")} className="px-3">
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>

                <p className="text-xs">End date</p>
                <div className="flex flex-row flex-nowrap">
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <button onClick={() => setEndDate("")} className="px-3">
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>

                <ImagePicker
                    selectedImage={image}
                    setSelectedImage={setImage}
                />
            </div>
            {id ? (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={update}
                    >
                        Update
                    </button>
                    <button className="p-3" onClick={deleteIt}>
                        <FontAwesomeIcon icon={faTrash} color="darkred" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-row gap-x-2 self-end">
                    <button
                        className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                        onClick={create}
                    >
                        Create
                    </button>
                    <button className="p-3" onClick={() => reset()}>
                        <FontAwesomeIcon icon={faUndo} />
                    </button>
                </div>
            )}
        </div>
    );
};
