import React, { useCallback, useMemo } from "react";
import {
    Timeline,
    TimelineItem,
    TimelineItemDetails,
} from "../../Components/Timeline/Timeline";
import { useExhibitions } from "../../State/Context/ExhibitionsContext";
import { ExhibitionItem } from "../../Types/ExhibitionItem";
import { useSiteContent } from "../../State/Context/SiteContentContext";
import { useSettings } from "../../State/Context/SettingsContext";

export const Bio: React.FC = () => {
    const { loading, exhibitions, soloExhibitions, groupExhibitions } = useExhibitions();
    const { bioImage } = useSiteContent();

    const { isFancyBioEnabled } = useSettings();

    const toTimelineItems = useCallback(
        (items: ExhibitionItem[]): TimelineItem[] => {
            const groupedItems = items.reduce((acc, item) => {
                const { time, title, description } = item;
                if (!acc[time]) {
                    acc[time] = [];
                }
                acc[time].push({ title, description });
                return acc;
            }, {} as { [key: string]: TimelineItemDetails[] });

            return Object.keys(groupedItems)
                .map((time) => ({
                    time,
                    details: groupedItems[time],
                }))
                .sort((a, b) => parseInt(b.time) - parseInt(a.time));
        },
        []
    );

    const timelineItems: TimelineItem[] = useMemo(
        () => toTimelineItems(exhibitions),
        [toTimelineItems, exhibitions]
    );

    const soloTimelineItems: TimelineItem[] = useMemo(
        () => toTimelineItems(soloExhibitions),
        [toTimelineItems, soloExhibitions]
    );

    const groupTimelineItems: TimelineItem[] = useMemo(
        () => toTimelineItems(groupExhibitions),
        [toTimelineItems, groupExhibitions]
    );

    const toUglyString = useCallback((items: TimelineItem[]): string => {
        let str = "";
        items.forEach((item) => {
            str += item.time;
            str += ": ";
            item.details.forEach((detail) => {
                str += detail.title;
                str += "-";
                str += detail.description;
                str += ", ";
            });
            str = str.substring(0, str.length - 2);
            str += " | ";
        });

        return str;
    }, []);

    const downloadExhibitions = useCallback(() => {
        let textContent = "";
        textContent += "solo";
        textContent += "\r\n";
        textContent += toUglyString(soloTimelineItems);
        textContent += "\r\n\r\n";
        textContent += "group";
        textContent += "\r\n";
        textContent += toUglyString(groupTimelineItems);

        const blob = new Blob([textContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "luc_hoekx_bio.txt";

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    }, [soloTimelineItems, groupTimelineItems, toUglyString]);

    if (loading) return <div>loading...</div>;

    return (
        <div className="flex flex-col lg:flex-row lg:flex-wrap gap-10">
            <img className="lg:flex-grow max-h-[80vh] lg:max-w-[50vw] object-contain object-top" src={bioImage} alt={'Bio'} />

            {isFancyBioEnabled ? (
                <div className="flex flex-col gap-y-5">
                    <div>
                        <h1>exhibitions</h1>
                        <button
                            onClick={() => downloadExhibitions()}
                            className="text-xs cursor-pointer"
                        >
                            download
                        </button>
                    </div>
                    <Timeline items={timelineItems} />
                </div>
            ) : (
                <div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-5">
                            <h2>solo</h2>
                            <p>{toUglyString(soloTimelineItems)}</p>
                        </div>

                        <div className="flex flex-col gap-5">
                            <h2>group</h2>
                            <p>{toUglyString(groupTimelineItems)}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
