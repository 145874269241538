import { combineReducers } from "redux";
import galleryReducer from "./Reducers/GalleryReducer";
import postsReducer from "./Reducers/PostsReducer";
import exhibitionsReducer from "./Reducers/ExhibitionsReducer";
import siteContentReducer from "./Reducers/SiteContentReducer";
import settingsReducer from "./Reducers/SettingsReducer";
import linksReducer from "./Reducers/LinksReducer";

const rootReducer = combineReducers({
    gallery: galleryReducer,
    posts: postsReducer,
    exhibitions: exhibitionsReducer,
    siteContent: siteContentReducer,
    settings: settingsReducer,
    links: linksReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
