import { ReactNode, createContext, useContext } from "react";
import { LinkItem } from "../../Types/LinkItem";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";

interface LinksContextType {
    loading: boolean;
    links: LinkItem[]
}

const LinksContext = createContext<LinksContextType | undefined>(
    undefined
);

export const LinksProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const loading = useSelector((state: RootState) => state.links.loading);
    const links = useSelector((state: RootState) => state.links.links);

    const providerContext: LinksContextType = {
        loading,
        links
    };

    return (
        <LinksContext.Provider value={providerContext}>
            {children}
        </LinksContext.Provider>
    )
};


export const useLinks = (): LinksContextType => {
    const context = useContext(LinksContext);
    if (!context) {
        throw new Error("useLinks must be used within a LinksProvider");
    }
    return context;
};