import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Post.css";
import React from "react";
import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { PostItem } from "../../Types/PostItem";
import { formatDate } from "../../utils";
import useScreenWidth from "../../Hooks/UseScreenWidth";
import { usePosts } from "../../State/Context/PostsContext";

export type PostProps = {
    post: PostItem;
};

export const Post: React.FC<PostProps> = ({ post }) => {
    const { screenSize } = useScreenWidth();

    const { setSelectedPost, setShowPostDetail } = usePosts();

    const showPostDetail = () => {
        setSelectedPost(post);
        setShowPostDetail(true);
    };

    if (screenSize === "sm" || screenSize === "md")
        return (
            <div className="flex flex-col w-[45vw] md:w-[35vw]">
                <img
                    draggable={false}
                    className="object-cover h-80 cursor-pointer"
                    src={post.image}
                    alt={`${post.title}`}
                    onClick={showPostDetail}
                />
                <div className="grid grid-cols-[auto,1fr] auto-rows-auto gap-3 items-center w-full">
                    <span />
                    <h2 className="font-semibold col-span-2">{post.title}</h2>
                    <FontAwesomeIcon size="sm" icon={faLocationPin} />
                    <span>{post.location}</span>
                    <FontAwesomeIcon size="sm" icon={faCalendarAlt} />
                    <span>
                        {formatDate(post.date.start)}
                        {post.date.end && ` - ${formatDate(post.date.end)}`}
                    </span>

                    {post.time && (
                        <>
                            <FontAwesomeIcon size="sm" icon={faClock} />
                            <span>
                                {post.time.start}
                                {post.time.end && ` - ${post.time.end}`}
                            </span>
                        </>
                    )}
                </div>
            </div>
        );

    return (
        <div className="flex flex-row gap-5 justify-center min-w-[400px]">
            <img
                draggable={false}
                className="h-[200px] w-[200px] object-cover cursor-pointer hover:opacity-80"
                src={post.image}
                alt={`${post.title}`}
                onClick={showPostDetail}
            />
            <div className="grid grid-cols-[auto,1fr] auto-rows-auto gap-3 items-center w-full">
                <h2 className="font-semibold col-span-2">{post.title}</h2>
                <FontAwesomeIcon size="sm" icon={faLocationPin} />
                <span>{post.location}</span>
                <FontAwesomeIcon size="sm" icon={faCalendarAlt} />
                <span>
                    {formatDate(post.date.start)}
                    {post.date.end && ` - ${formatDate(post.date.end)}`}
                </span>

                {post.time && (
                    <>
                        <FontAwesomeIcon size="sm" icon={faClock} />
                        <span>
                            {post.time.start}
                            {post.time.end && ` - ${post.time.end}`}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};
