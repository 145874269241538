import { useEffect, useRef, useState } from "react";

export interface DraggableProps<T> {
    items: T[];
    renderItem: (item: T, index: number) => React.ReactNode;
    callback: (items: T[]) => any;
    triggerCallback: boolean;
    setTriggerCallback: React.Dispatch<React.SetStateAction<boolean>>;
    dragEnabled: boolean;
}

export const Draggable = <T,>({
    items,
    renderItem,
    dragEnabled,
    callback,
    triggerCallback,
    setTriggerCallback,
}: DraggableProps<T>) => {
    const [displayItems, setDisplayItems] = useState<T[]>([]);

    const draggingPos = useRef<number | undefined>(undefined);
    const dragOverPos = useRef<number | undefined>(undefined);

    useEffect(() => {
        setDisplayItems([...items]);
    }, [items, setDisplayItems])

    const handleDragStart = (position: number) => {
        draggingPos.current = position;
    };

    const handleDragEnter = (position: number) => {
        if (draggingPos.current === undefined) return;

        dragOverPos.current = position;
        const newItems = [...displayItems];
        const draggingItem = newItems[draggingPos.current];
        if (!draggingItem) return;

        newItems.splice(draggingPos.current, 1);
        newItems.splice(dragOverPos.current, 0, draggingItem);

        draggingPos.current = position;
        dragOverPos.current = undefined;

        setDisplayItems(newItems);
    };

    useEffect(() => {
        if (!triggerCallback) return;
        
        callback(displayItems);
        setTriggerCallback(false);
    }, [triggerCallback, callback, setTriggerCallback, displayItems]);

    if (!dragEnabled)
        return <>{displayItems.map((item, i) => renderItem(item, i))}</>;

    return (
        <>
            {displayItems.map((item, i) => (
                <div
                    key={i}
                    draggable
                    onDragStart={() => handleDragStart(i)}
                    onDragEnter={() => handleDragEnter(i)}
                    onDragOver={(e) => e.preventDefault()}
                >
                    {renderItem(item, i)}
                </div>
            ))}
        </>
    );
};
