import { useEffect } from "react";
import { NavigationBar } from "./Components/NavigationBar/NavigationBar";
import { Home } from "./Pages/Home/Home";
import { Footer } from "./Components/Footer/Footer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Gallery } from "./Pages/Gallery/Gallery";
import { Upcoming } from "./Pages/Upcoming/Upcoming";
import { Bio } from "./Pages/Bio/Bio";
import { useDispatch } from "react-redux";
import { getCompleteGallery } from "./State/Actions/GalleryActions";
import { Overview } from "./Pages/Overview/Overview";
import { getAllPosts } from "./State/Actions/PostActions";
import "./App.css";
import { getAllExhibitions } from "./State/Actions/ExhibitionsActions";
import { useAdmin } from "./State/Context/AdminContext";
import { Admin } from "./Pages/Admin/Admin";
import { getSiteContent } from "./State/Actions/SiteContentActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getAllSettings } from "./State/Actions/SettingsActions";
import { Links } from "./Pages/Links/Links";
import { getAllLinks } from "./State/Actions/LInksActions";
import Modal from "react-modal";
import { PostDetail } from "./Components/PostDetail/PostDetail";
import { usePosts } from "./State/Context/PostsContext";

Modal.setAppElement("#root");

function App() {
    const dispatch = useDispatch<any>();
    const modalStyle = {
        content: {
            height: "70vh",
            width: "80vw",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
        },
    };

    useEffect(() => {
        dispatch(getCompleteGallery());
        dispatch(getAllPosts());
        dispatch(getAllExhibitions());
        dispatch(getSiteContent());
        dispatch(getAllSettings());
        dispatch(getAllLinks());
    }, [dispatch]);

    const { showPostDetail, setShowPostDetail } = usePosts();

    const { adminMode } = useAdmin();

    return (
        <BrowserRouter>
            <Modal
                style={modalStyle}
                isOpen={showPostDetail}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                onRequestClose={() => setShowPostDetail(false)}
            >
                <PostDetail />
            </Modal>
            <div className="flex flex-col min-h-screen">
                <NavigationBar />
                <div className="flex-grow p-5">
                    <ToastContainer />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route
                            path="/gallery/:groupBy/:id"
                            element={<Overview />}
                        />
                        <Route path="/upcoming" element={<Upcoming />} />
                        <Route path="/bio" element={<Bio />} />
                        <Route path="/links" element={<Links />} />
                        {adminMode && (
                            <Route path="/admin" element={<Admin />} />
                        )}
                    </Routes>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
