export const GET_SITE_CONTENT = "GET_SITE_CONTENT";
export const GET_SITE_CONTENT_SUCCESS = "GET_SITE_CONTENT_SUCCESS";
export const GET_SITE_CONTENT_FAILURE = "GET_SITE_CONTENT_FAILURE";
export const UPDATE_SITE_CONTENT = "UPDATE_SITE_CONTENT";
export const UPDATE_SITE_CONTENT_SUCCESS = "UPDATE_SITE_CONTENT_SUCCESS";
export const UPDATE_SITE_CONTENT_FAILURE = "UPDATE_SITE_CONTENT_FAILURE";

// Get all

export interface GetSiteContentAction {
    type: typeof GET_SITE_CONTENT;
}

export interface GetSiteContentSuccessAction {
    type: typeof GET_SITE_CONTENT_SUCCESS;
    payload: {
        images: string[];
    };
}

export interface GetSiteContentFailureAction {
    type: typeof GET_SITE_CONTENT_FAILURE;
    error: string;
}

export type GetSiteContentActionTypes =
    | GetSiteContentAction
    | GetSiteContentSuccessAction
    | GetSiteContentFailureAction;

// Update image

export interface UpdateSiteContentAction {
    type: typeof UPDATE_SITE_CONTENT;
    payload: {
        page: string;
    };
}

export interface UpdateSiteContentSuccessAction {
    type: typeof UPDATE_SITE_CONTENT_SUCCESS;
    payload: {
        page: string;
        newImageUrl: string;
    };
}

export interface UpdateSiteContentFailureAction {
    type: typeof UPDATE_SITE_CONTENT_FAILURE;
    payload: {
        page: string;
        error: string;
    };
}

export type UpdateSiteContentActionTypes =
    | UpdateSiteContentAction
    | UpdateSiteContentSuccessAction
    | UpdateSiteContentFailureAction;

export type SiteContentActionTypes =
    | GetSiteContentActionTypes
    | UpdateSiteContentActionTypes;
