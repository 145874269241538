import React, { useEffect, useState } from "react";
import { Grouping } from "../../Components/Grouping/Grouping";
import { useGallery } from "../../State/Context/GalleryContext";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../Hooks/UseScreenWidth";
import { usePosts } from "../../State/Context/PostsContext";
import { Post } from "../../Components/Post/Post";
import { useSiteContent } from "../../State/Context/SiteContentContext";
import { useSettings } from "../../State/Context/SettingsContext";
import { GalleryItem } from "../../Types/GalleryItem";

export const Home: React.FC = () => {
    const {
        loading: galleryLoading,
        recentGalleryItems,
        galleryItems,
    } = useGallery();
    const { loading: settingsLoading, recents } = useSettings();
    const { upcoming } = usePosts();
    const navigate = useNavigate();

    const { screenSize } = useScreenWidth();

    const { homeImage } = useSiteContent();

    const [recentsList, setRecentsList] = useState<GalleryItem[]>([]);

    useEffect(() => {
        if (galleryLoading || settingsLoading) return;

        if (recents.length > 0) {
            setRecentsList(galleryItems.filter((x) => recents.includes(x.id)));
        } else {
            setRecentsList(recentGalleryItems);
        }
    }, [
        galleryLoading,
        settingsLoading,
        recents,
        galleryItems,
        recentGalleryItems,
        setRecentsList,
    ]);

    return (
        <>
            <div className="flex flex-col xl:flex-row gap-5">
                {/* Recent uploads */}
                <div className="flex-grow">
                    <img
                        className="h-full w-full object-cover max-h-80vh"
                        src={homeImage}
                        alt="Hero"
                    />
                </div>

                {/* Upcoming events */}
                {upcoming.length > 0 && (
                    <aside className="flex flex-col">
                        <h2 className="font-semibold">coming soon</h2>
                        <div
                            className={`flex flex-row xl:flex-col ${
                                screenSize === "sm" || screenSize === "md"
                                    ? "overflow-x-scroll gap-x-10 gap-y-2"
                                    : "overflow-y-scroll gap-x-2 gap-y-5"
                            }`}
                        >
                            {upcoming.map((x) => (
                                <div key={x.id}>
                                    <Post post={x} />
                                </div>
                            ))}
                        </div>
                    </aside>
                )}
            </div>
            <div>
                <Grouping
                    collapsable={false}
                    navigateToOverview={() => navigate("/gallery")}
                    limitItemsTo={screenSize === "sm" ? 3 : 5}
                    galleryItems={recentsList}
                    groupingItem={{
                        categoryId: -1,
                        title: "recent",
                        order: 0,
                    }}
                />
            </div>
        </>
    );
};
