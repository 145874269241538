import { Dispatch } from "redux";
import { CreateExhibition, ExhibitionItem } from "../../Types/ExhibitionItem";
import {
    CREATE_EXHIBITION,
    CREATE_EXHIBITION_FAILURE,
    CREATE_EXHIBITION_SUCCESS,
    CreateExhibitionAction,
    CreateExhibitionActionTypes,
    CreateExhibitionFailureAction,
    CreateExhibitionSuccessAction,
    DELETE_EXHIBITION,
    DELETE_EXHIBITION_FAILURE,
    DELETE_EXHIBITION_SUCCESS,
    DeleteExhibitionAction,
    DeleteExhibitionActionTypes,
    DeleteExhibitionFailureAction,
    DeleteExhibitionSuccessAction,
    GET_EXHIBITIONS,
    GET_EXHIBITIONS_FAILURE,
    GET_EXHIBITIONS_SUCCESS,
    GetExhibitionsAction,
    GetExhibitionsActionTypes,
    GetExhibitionsFailureAction,
    GetExhibitionsSuccessAction,
    UPDATE_EXHIBITION,
    UPDATE_EXHIBITION_FAILURE,
    UPDATE_EXHIBITION_SUCCESS,
    UPDATE_EXHIBITIONS,
    UPDATE_EXHIBITIONS_FAILURE,
    UPDATE_EXHIBITIONS_SUCCESS,
    UpdateExhibitionAction,
    UpdateExhibitionActionTypes,
    UpdateExhibitionFailureAction,
    UpdateExhibitionsActionTypes,
    UpdateExhibitionsFailureAction,
    UpdateExhibitionsSuccessAction,
    UpdateExhibitionSuccessAction,
} from "./ActionTypes/Exhibitions";
import { ExhibitionsState } from "../Reducers/ExhibitionsReducer";
import * as db from "../Database/exhibitions";
import { toast } from "react-toastify";
import { GalleryState } from "../Reducers/GalleryReducer";

type GetExhibitionsResponse = {
    exhibitions: ExhibitionItem[];
};

const getExhibitionsRequest = (): GetExhibitionsAction => ({
    type: GET_EXHIBITIONS,
});

const getExhibitionsSuccess = (
    payload: GetExhibitionsResponse
): GetExhibitionsSuccessAction => ({
    type: GET_EXHIBITIONS_SUCCESS,
    payload,
});

const getExhibitionsFailure = (error: string): GetExhibitionsFailureAction => ({
    type: GET_EXHIBITIONS_FAILURE,
    error,
});

export const getAllExhibitions = () => {
    return async (
        dispatch: Dispatch<GetExhibitionsActionTypes>,
        getState: () => ExhibitionsState
    ) => {
        dispatch(getExhibitionsRequest());

        try {
            const response = await db.getExhibitions();
            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong getting exhibitions");
            }

            const data: GetExhibitionsResponse = {
                exhibitions: response.exhibitions,
            };

            dispatch(getExhibitionsSuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getExhibitionsFailure(error.message));
        }
    };
};

const createExhibitionRequest = (): CreateExhibitionAction => ({
    type: CREATE_EXHIBITION,
});

const createExhibitionSuccess = (
    exhibition: ExhibitionItem
): CreateExhibitionSuccessAction => ({
    type: CREATE_EXHIBITION_SUCCESS,
    payload: {
        exhibition,
    },
});

const createExhibitionFailure = (error: string): CreateExhibitionFailureAction => ({
    type: CREATE_EXHIBITION_FAILURE,
    error,
});

export const createExhibition = (exhibition: CreateExhibition) => {
    return async (
        dispatch: Dispatch<CreateExhibitionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(createExhibitionRequest());
        const toastId = toast.loading("Creating exhibition...");

        try {
            const response = await db.createExhibition(exhibition);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating exhibition");
            }

            toast.update(toastId, {
                render: "Exhibition created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createExhibitionSuccess(response.exhibition));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createExhibitionFailure(error.message));
        }
    };
};

const updateExhibitionRequest = (): UpdateExhibitionAction => ({
    type: UPDATE_EXHIBITION,
});

const updateExhibitionSuccess = (
    exhibition: ExhibitionItem
): UpdateExhibitionSuccessAction => ({
    type: UPDATE_EXHIBITION_SUCCESS,
    payload: {
        exhibition,
    },
});

const updateExhibitionFailure = (error: string): UpdateExhibitionFailureAction => ({
    type: UPDATE_EXHIBITION_FAILURE,
    error,
});

export const updateExhibition = (exhibition: ExhibitionItem) => {
    return async (
        dispatch: Dispatch<UpdateExhibitionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateExhibitionRequest());
        const toastId = toast.loading(`Saving exhibition...`);

        try {
            const response = await db.updateExhibition(exhibition);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating exhibition");
            }

            toast.update(toastId, {
                render: "Exhibition updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateExhibitionSuccess(response.exhibition));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateExhibitionFailure(error.message));
        }
    };
};

const updateExhibitionsRequest = (): UpdateExhibitionsActionTypes => ({
    type: UPDATE_EXHIBITIONS,
});

const updateExhibitionsSuccess = (
    exhibitions: ExhibitionItem[]
): UpdateExhibitionsSuccessAction => ({
    type: UPDATE_EXHIBITIONS_SUCCESS,
    payload: {
        exhibitions,
    },
});

const updateExhibitionsFailure = (
    error: string
): UpdateExhibitionsFailureAction => ({
    type: UPDATE_EXHIBITIONS_FAILURE,
    error,
});

export const updateExhibitions = (exhibitions: ExhibitionItem[]) => {
    return async (
        dispatch: Dispatch<UpdateExhibitionsActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateExhibitionsRequest());

        const toastId = toast.loading("Saving exhibitions...");

        try {
            const response = await db.updateExhibitions(exhibitions);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating exhibitions");
            }

            toast.update(toastId, {
                render: "Categories updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateExhibitionsSuccess(response.exhibitions));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateExhibitionsFailure(error.message));
        }
    };
};

const deleteExhibitionRequest = (): DeleteExhibitionAction => ({
    type: DELETE_EXHIBITION,
});

const deleteExhibitionSuccess = (
    exhibitionId: number
): DeleteExhibitionSuccessAction => ({
    type: DELETE_EXHIBITION_SUCCESS,
    payload: {
        exhibitionId,
    },
});

const deleteExhibitionFailure = (error: string): DeleteExhibitionFailureAction => ({
    type: DELETE_EXHIBITION_FAILURE,
    error,
});

export const deleteExhibition = (exhibitionId: number) => {
    return async (
        dispatch: Dispatch<DeleteExhibitionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(deleteExhibitionRequest());
        const toastId = toast.loading("Deleting exhibition...");

        try {
            const response = await db.deleteExhibition(exhibitionId);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong deleting exhibition");
            }

            toast.update(toastId, {
                render: "Exhibition deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deleteExhibitionSuccess(exhibitionId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deleteExhibitionFailure(error.message));
        }
    };
};