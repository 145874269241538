import { useDispatch } from "react-redux";
import { ExhibitionItem } from "../../../Types/ExhibitionItem";
import { useState } from "react";
import {
    createExhibition,
    deleteExhibition,
    updateExhibition,
} from "../../../State/Actions/ExhibitionsActions";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { getLast30Years } from "../../../utils";
import { useExhibitions } from "../../../State/Context/ExhibitionsContext";

type ExhibitionFormProps = {
    exhibition?: ExhibitionItem;
};
export const ExhibitionForm: React.FC<ExhibitionFormProps> = ({
    exhibition,
}) => {
    const dispatch = useDispatch<any>();
    const [id] = useState(exhibition?.id);
    const [exhibitionTypeId, setExhibitionTypeId] = useState(
        exhibition?.exhibitionTypeId ?? 1
    );
    const [title, setTitle] = useState(exhibition?.title ?? "");
    const [description, setDescription] = useState(
        exhibition?.description ?? ""
    );
    const [time, setTime] = useState(
        exhibition?.time ?? new Date().getFullYear().toString()
    );

    const { exhibitionsCount } = useExhibitions();

    const create = () => {
        if (!exhibitionTypeId || !title || !time) return;

        dispatch(
            createExhibition({
                exhibitionTypeId,
                title,
                description,
                time,
                order:
                    exhibitionsCount.find((x) => x.time === time)?.count ?? 0,
            })
        );

        reset();
    };

    const update = () => {
        if (!id || !exhibitionTypeId || !title || !time) return;

        dispatch(
            updateExhibition({
                id,
                exhibitionTypeId,
                title,
                description,
                time,
                order: exhibition!.order,
            })
        );
    };

    const deleteIt = () => {
        if (!id) return;

        confirmAlert({
            title: `Delete ${title} - ${time}`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deleteExhibition(id)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    const reset = () => {
        setTitle("");
        setDescription("");
        setTime("");
        setExhibitionTypeId(0);
    };

    return (
        <div className="flex flex-row gap-5 bg-site-accent bg-opacity-10 rounded-md p-2">
            <div className="flex flex-col gap-5">
                <p className="text-xs">Title</p>
                <input
                    placeholder="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                ></input>

                <p className="text-xs">Description</p>
                <textarea
                    placeholder="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>

                <p className="text-xs">Type</p>
                <select
                    value={exhibitionTypeId}
                    onChange={(e) =>
                        setExhibitionTypeId(parseInt(e.target.value))
                    }
                >
                    <option value={1}>Solo</option>
                    <option value={2}>Group</option>
                </select>

                <p className="text-xs">Year</p>
                <select value={time} onChange={(e) => setTime(e.target.value)}>
                    {getLast30Years().map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                {id ? (
                    <div className="flex flex-row gap-x-2 self-end">
                        <button
                            className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                            onClick={update}
                        >
                            Update
                        </button>
                        <button className="p-3" onClick={deleteIt}>
                            <FontAwesomeIcon icon={faTrash} color="darkred" />
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-row gap-x-2 self-end">
                        <button
                            className="p-3 bg-site-accent text-white rounded-md hover:bg-[#233616]"
                            onClick={create}
                        >
                            Create
                        </button>
                        <button className="p-3" onClick={() => reset()}>
                            <FontAwesomeIcon icon={faUndo} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
