import { ReactNode, createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";

interface SiteContentContextType {
    homeImage?: string;
    bioImage?: string;
}

const SiteContentContext = createContext<SiteContentContextType | undefined>(undefined);

export const SiteContentProvider: React.FC<{children: ReactNode}> = ({
    children
}) => {
    const homeImage = useSelector((state: RootState) => state.siteContent.images.find(x => x.includes('home')));
    const bioImage = useSelector((state: RootState) => state.siteContent.images.find(x => x.includes('bio')));
    
    const providerContext: SiteContentContextType = {
        homeImage,
        bioImage
    }

    return (
        <SiteContentContext.Provider value={providerContext}>
            {children}
        </SiteContentContext.Provider>
    )
}

export const useSiteContent = (): SiteContentContextType => {
    const context = useContext(SiteContentContext);
    if (!context) {
        throw new Error("useSiteContent must be used within a sitecontentprovider");
    }
    return context;
}