import React, { useState } from "react";
import { useGallery } from "../../../State/Context/GalleryContext";
import { CategoryForm } from "./../Forms/CategoryForm";
import { Draggable } from "../../../Components/Draggable/Draggable";
import { GalleryCategory } from "../../../Types/GalleryCategory";
import { useDispatch } from "react-redux";
import { updateCategories } from "../../../State/Actions/GalleryActions";

export const CategoryManager: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { categories } = useGallery();

    const [saveOrder, setSaveOrder] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);

    const callback = (items: GalleryCategory[]) => {
        setDragEnabled(false);

        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index,
        }));

        dispatch(updateCategories(updatedItems));
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-x-5">
                <label htmlFor="category_drag">
                    <input
                        checked={dragEnabled}
                        onChange={(e) => setDragEnabled(e.target.checked)}
                        className="px-5"
                        type="checkbox"
                        id="category_drag"
                    />
                    &nbsp;Reorder
                </label>
                <button
                    className={`${!dragEnabled && "hidden"}`}
                    onClick={() => setSaveOrder(true)}
                >
                    Volgorde opslaan
                </button>
            </div>
            <Draggable
                dragEnabled={dragEnabled}
                triggerCallback={saveOrder}
                setTriggerCallback={setSaveOrder}
                callback={callback}
                items={categories}
                renderItem={(category, i) => (
                    <CategoryForm
                        category={category}
                        key={category.categoryId}
                        readonly={dragEnabled}
                    />
                )}
            />
            <CategoryForm readonly={dragEnabled} />
        </div>
    );
};
