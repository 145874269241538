import React from "react";
import "./Admin.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Collapsable } from "../../Components/Collapsable/Collapsable";
import { CategoryManager } from "./Managers/CategoryManager";
import { CollectionManager } from "./Managers/CollectionManager";
import { GalleryManager } from "./Managers/GalleryManager";
import { PostManager } from "./Managers/PostManager";
import { PhotoManager } from "./Managers/PhotoManager";
import { ExhibitionsManager } from "./Managers/ExhibitionsManager";
import { LinksManager } from "./Managers/LinksManager";
import { SettingsManager } from "./Managers/SettingsManager";
import { GalleryOrderManager } from "./Managers/GalleryOrderManager";
import { ExhibitionsOrderManager } from "./Managers/ExhibitionsOrderManager";
import { GalleryRecentsManager } from "./Managers/GalleryRecentsManager";

type SectionTitleProps = {
    title: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => (
    <h2 className="font-semibold">{title}</h2>
);

export const Admin: React.FC = () => {
    return (
        <div className="flex flex-col items-start gap-20">
            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Photo management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <PhotoManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Category management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <CategoryManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Collection management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <CollectionManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Gallery management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <GalleryManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Gallery order management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <GalleryOrderManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Gallery recents management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <GalleryRecentsManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Upcoming management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <PostManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Exhibitions management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <ExhibitionsManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Exhibitions order management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <ExhibitionsOrderManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Links management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <LinksManager />
                </Collapsable.Body>
            </Collapsable>

            <Collapsable>
                <Collapsable.Header>
                    <SectionTitle title="Settings management" />
                </Collapsable.Header>
                <Collapsable.Body>
                    <SettingsManager />
                </Collapsable.Body>
            </Collapsable>
        </div>
    );
};
