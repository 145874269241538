import React from "react";
import { HomeForm } from "./../Forms/HomeForm";
import { BioForm } from "./../Forms/BioForm";


export const PhotoManager: React.FC = () => {
    return (
        <div className="flex flex-col md:flex-row gap-5">
            <HomeForm />
            <BioForm />
        </div>
    );
};
