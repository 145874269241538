import { generateGuid } from "../../Utils/uuid";
import supabase from "./client";

export const uploadImage = async (
    image: File,
    folder: string
): Promise<
    { success: true; path: string } | { success: false; error: string }
> => {
    const fileExtension = image.name.split(".").pop();
    const guid = generateGuid();

    const { data, error } = await supabase.storage
        .from(folder)
        .upload(`${guid}.${fileExtension}`, image);

    if (error) {
        return {
            success: false,
            error: error.message,
        };
    }

    const {
        data: { publicUrl },
    } = await supabase.storage.from(folder).getPublicUrl(data.path);

    return { success: true, path: publicUrl };
};
