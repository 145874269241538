export const formatDate = (date: string): string => {
    const split = date.split('-');
    const year = split[0];
    const month = split[1];
    const days = split[2];

    return `${days}-${month}-${year}`;
};

export const getLast30Years = (): string[] => {
    const currentYear = new Date().getFullYear();
    const years = [];
    
    for (let i = 0; i < 30; i++) {
      years.push((currentYear - i).toString());
    }
    
    return years;
  };
