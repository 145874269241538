import {
    GET_SITE_CONTENT,
    GET_SITE_CONTENT_FAILURE,
    GET_SITE_CONTENT_SUCCESS,
    UPDATE_SITE_CONTENT,
    UPDATE_SITE_CONTENT_SUCCESS,
    SiteContentActionTypes
} from "../Actions/ActionTypes/SiteContent";

export type SiteContentState = {
    images: string[];
    loading: boolean;
    error?: string;
};

const initialSate: SiteContentState = {
    images: [],
    loading: false,
    error: undefined,
};

const siteContentReducer = (
    state = initialSate,
    action: SiteContentActionTypes
) => {
    switch (action.type) {
        case GET_SITE_CONTENT:
            return {
                ...state,
                loading: true,
            };

        case GET_SITE_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                images: action.payload.images,
            };

        case GET_SITE_CONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case UPDATE_SITE_CONTENT:
            return {
                ...state,
            };

        case UPDATE_SITE_CONTENT_SUCCESS:
            return {
                ...state,
                images: [
                    ...state.images.filter(
                        (x) => !x.includes(action.payload.newImageUrl)
                    ),
                    action.payload.newImageUrl,
                ],
            };

        default:
            return {
                ...state,
            };
    }
};

export default siteContentReducer;
