import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./State/Store";
import { RootProvider } from "./State/Context/RootProvider";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <RootProvider>
            <App />
        </RootProvider>
    </Provider>
);
