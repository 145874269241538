import { Dispatch } from "redux";
import { CreateCategory, GalleryCategory } from "../../Types/GalleryCategory";
import {
    CreateGalleryItem,
    GalleryItem,
    UpdateGalleryItem,
} from "../../Types/GalleryItem";
import {
    CREATE_CATEGORY,
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_SUCCESS,
    CREATE_COLLECTION,
    CREATE_COLLECTION_FAILURE,
    CREATE_COLLECTION_SUCCESS,
    CREATE_GALLERY_ITEM,
    CREATE_GALLERY_ITEM_FAILURE,
    CREATE_GALLERY_ITEM_SUCCESS,
    CreateCategoryAction,
    CreateCategoryActionTypes,
    CreateCategoryFailureAction,
    CreateCategorySuccessAction,
    CreateCollectionAction,
    CreateCollectionActionTypes,
    CreateCollectionFailureAction,
    CreateCollectionSuccessAction,
    CreateGalleryItemAction,
    CreateGalleryItemActionTypes,
    CreateGalleryItemFailureAction,
    CreateGalleryItemSuccessAction,
    DELETE_CATEGORY,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    DELETE_COLLECTION,
    DELETE_COLLECTION_FAILURE,
    DELETE_COLLECTION_SUCCESS,
    DELETE_GALLERY_ITEM,
    DELETE_GALLERY_ITEM_FAILURE,
    DELETE_GALLERY_ITEM_SUCCESS,
    DeleteCategoryAction,
    DeleteCategoryActionTypes,
    DeleteCategoryFailureAction,
    DeleteCategorySuccessAction,
    DeleteCollectionAction,
    DeleteCollectionActionTypes,
    DeleteCollectionFailureAction,
    DeleteCollectionSuccessAction,
    DeleteGalleryItemAction,
    DeleteGalleryItemActionTypes,
    DeleteGalleryItemFailureAction,
    DeleteGalleryItemSuccessAction,
    GET_GALLERY,
    GET_GALLERY_FAILURE,
    GET_GALLERY_SUCCESS,
    GetGalleryAction,
    GetGalleryActionTypes,
    GetGalleryFailureAction,
    GetGallerySuccessAction,
    UPDATE_CATEGORIES,
    UPDATE_CATEGORIES_FAILURE,
    UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_COLLECTION,
    UPDATE_COLLECTION_FAILURE,
    UPDATE_COLLECTION_SUCCESS,
    UPDATE_COLLECTIONS,
    UPDATE_COLLECTIONS_FAILURE,
    UPDATE_COLLECTIONS_SUCCESS,
    UPDATE_GALLERY_ITEM,
    UPDATE_GALLERY_ITEM_FAILURE,
    UPDATE_GALLERY_ITEM_SUCCESS,
    UPDATE_GALLERY_ITEMS,
    UPDATE_GALLERY_ITEMS_FAILURE,
    UPDATE_GALLERY_ITEMS_SUCCESS,
    UpdateCategoriesActionTypes,
    UpdateCategoriesFailureAction,
    UpdateCategoriesSuccessAction,
    UpdateCategoryAction,
    UpdateCategoryActionTypes,
    UpdateCategoryFailureAction,
    UpdateCategorySuccessAction,
    UpdateCollectionAction,
    UpdateCollectionActionTypes,
    UpdateCollectionFailureAction,
    UpdateCollectionsAction,
    UpdateCollectionsActionTypes,
    UpdateCollectionsFailureAction,
    UpdateCollectionsSuccessAction,
    UpdateCollectionSuccessAction,
    UpdateGalleryItemAction,
    UpdateGalleryItemActionTypes,
    UpdateGalleryItemFailureAction,
    UpdateGalleryItemsAction,
    UpdateGalleryItemsActionTypes,
    UpdateGalleryItemsFailureAction,
    UpdateGalleryItemsSuccessAction,
    UpdateGalleryItemSuccessAction,
} from "./ActionTypes/Gallery";
import { GalleryState } from "../Reducers/GalleryReducer";
import {
    CreateCollection,
    GalleryCollection,
} from "../../Types/GalleryCollection";

import * as db from "../Database/gallery";
import { toast } from "react-toastify";

type GetGalleryResponse = {
    gallery: GalleryItem[];
    categories: GalleryCategory[];
    collections: GalleryCollection[];
};

const getGalleryRequest = (): GetGalleryAction => ({
    type: GET_GALLERY,
});

const getGallerySuccess = (
    payload: GetGalleryResponse
): GetGallerySuccessAction => ({
    type: GET_GALLERY_SUCCESS,
    payload,
});

const getGalleryFailure = (error: string): GetGalleryFailureAction => ({
    type: GET_GALLERY_FAILURE,
    error,
});

export const getCompleteGallery = () => {
    return async (
        dispatch: Dispatch<GetGalleryActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(getGalleryRequest());

        try {
            const response = await db.getEntireGallery();
            if (response.errors && response.errors.length > 0) {
                console.log("Error getting gallery", response.errors);
                throw new Error("Something went wrong getting the gallery.");
            }

            const data: GetGalleryResponse = {
                gallery: response.galleryItems,
                categories: response.categories,
                collections: response.collections,
            };

            dispatch(getGallerySuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getGalleryFailure(error.message));
        }
    };
};

const createCategoryRequest = (): CreateCategoryAction => ({
    type: CREATE_CATEGORY,
});

const createCategorySuccess = (
    category: GalleryCategory
): CreateCategorySuccessAction => ({
    type: CREATE_CATEGORY_SUCCESS,
    payload: {
        category,
    },
});

const createCategoryFailure = (error: string): CreateCategoryFailureAction => ({
    type: CREATE_CATEGORY_FAILURE,
    error,
});

export const createCategory = (category: CreateCategory) => {
    return async (
        dispatch: Dispatch<CreateCategoryActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(createCategoryRequest());
        const toastId = toast.loading("Creating category...");

        try {
            const response = await db.createCategory(category);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating category");
            }

            toast.update(toastId, {
                render: "Category created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createCategorySuccess(response.category));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createCategoryFailure(error.message));
        }
    };
};

const updateCategoryRequest = (): UpdateCategoryAction => ({
    type: UPDATE_CATEGORY,
});

const updateCategorySuccess = (
    category: GalleryCategory
): UpdateCategorySuccessAction => ({
    type: UPDATE_CATEGORY_SUCCESS,
    payload: {
        category,
    },
});

const updateCategoryFailure = (error: string): UpdateCategoryFailureAction => ({
    type: UPDATE_CATEGORY_FAILURE,
    error,
});

export const updateCategory = (category: GalleryCategory) => {
    return async (
        dispatch: Dispatch<UpdateCategoryActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateCategoryRequest());
        const toastId = toast.loading(`Saving category...`);

        try {
            const response = await db.updateCategory(category);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating category");
            }

            toast.update(toastId, {
                render: "Category updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateCategorySuccess(response.category));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateCategoryFailure(error.message));
        }
    };
};

const updateCategoriesRequest = (): UpdateCategoriesActionTypes => ({
    type: UPDATE_CATEGORIES,
});

const updateCategoriesSuccess = (
    categories: GalleryCategory[]
): UpdateCategoriesSuccessAction => ({
    type: UPDATE_CATEGORIES_SUCCESS,
    payload: {
        categories,
    },
});

const updateCategoriesFailure = (
    error: string
): UpdateCategoriesFailureAction => ({
    type: UPDATE_CATEGORIES_FAILURE,
    error,
});

export const updateCategories = (categories: GalleryCategory[]) => {
    return async (
        dispatch: Dispatch<UpdateCategoriesActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateCategoriesRequest());

        const toastId = toast.loading("Saving categories...");

        try {
            const response = await db.updateCategories(categories);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating categories");
            }

            toast.update(toastId, {
                render: "Categories updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateCategoriesSuccess(response.categories));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateCategoriesFailure(error.message));
        }
    };
};

const deleteCategoryRequest = (): DeleteCategoryAction => ({
    type: DELETE_CATEGORY,
});

const deleteCategorySuccess = (
    categoryId: number
): DeleteCategorySuccessAction => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: {
        categoryId,
    },
});

const deleteCategoryFailure = (error: string): DeleteCategoryFailureAction => ({
    type: DELETE_CATEGORY_FAILURE,
    error,
});

export const deleteCategory = (categoryId: number) => {
    return async (
        dispatch: Dispatch<DeleteCategoryActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(deleteCategoryRequest());
        const toastId = toast.loading("Deleting category...");

        try {
            const response = await db.deleteCategory(categoryId);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong deleting category");
            }

            toast.update(toastId, {
                render: "Category deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deleteCategorySuccess(categoryId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deleteCategoryFailure(error.message));
        }
    };
};

const createCollectionRequest = (): CreateCollectionAction => ({
    type: CREATE_COLLECTION,
});

const createCollectionSuccess = (
    collection: GalleryCollection
): CreateCollectionSuccessAction => ({
    type: CREATE_COLLECTION_SUCCESS,
    payload: {
        collection,
    },
});

const createCollectionFailure = (
    error: string
): CreateCollectionFailureAction => ({
    type: CREATE_COLLECTION_FAILURE,
    error,
});

export const createCollection = (collection: CreateCollection) => {
    return async (
        dispatch: Dispatch<CreateCollectionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(createCollectionRequest());
        const toastId = toast.loading("Creating collection...");

        try {
            const response = await db.createCollection(collection);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating collection");
            }

            toast.update(toastId, {
                render: "Collection created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createCollectionSuccess(response.collection));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createCollectionFailure(error.message));
        }
    };
};

const updateCollectionRequest = (): UpdateCollectionAction => ({
    type: UPDATE_COLLECTION,
});

const updateCollectionSuccess = (
    collection: GalleryCollection
): UpdateCollectionSuccessAction => ({
    type: UPDATE_COLLECTION_SUCCESS,
    payload: {
        collection,
    },
});

const updateCollectionFailure = (
    error: string
): UpdateCollectionFailureAction => ({
    type: UPDATE_COLLECTION_FAILURE,
    error,
});

export const updateCollection = (collection: GalleryCollection) => {
    return async (
        dispatch: Dispatch<UpdateCollectionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateCollectionRequest());
        const toastId = toast.loading(`Saving collection...`);

        try {
            const response = await db.updateCollection(collection); // do stuff

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating collection");
            }

            toast.update(toastId, {
                render: "Collection updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateCollectionSuccess(response.collection));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateCollectionFailure(error.message));
        }
    };
};

const updateCollectionsRequest = (): UpdateCollectionsAction => ({
    type: UPDATE_COLLECTIONS,
});

const updateCollectionsSuccess = (
    collections: GalleryCollection[]
): UpdateCollectionsSuccessAction => ({
    type: UPDATE_COLLECTIONS_SUCCESS,
    payload: {
        collections,
    },
});

const updateCollectionsFailure = (
    error: string
): UpdateCollectionsFailureAction => ({
    type: UPDATE_COLLECTIONS_FAILURE,
    error,
});

export const updateCollections = (collections: GalleryCollection[]) => {
    return async (
        dispatch: Dispatch<UpdateCollectionsActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateCollectionsRequest());

        const toastId = toast.loading("Saving collections...");

        try {
            const response = await db.updateCollections(collections);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating collections");
            }

            toast.update(toastId, {
                render: "Collections updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateCollectionsSuccess(response.collections));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateCollectionsFailure(error.message));
        }
    };
};

const deleteCollectionRequest = (): DeleteCollectionAction => ({
    type: DELETE_COLLECTION,
});

const deleteCollectionSuccess = (
    collectionId: number
): DeleteCollectionSuccessAction => ({
    type: DELETE_COLLECTION_SUCCESS,
    payload: {
        collectionId,
    },
});

const deleteCollectionFailure = (
    error: string
): DeleteCollectionFailureAction => ({
    type: DELETE_COLLECTION_FAILURE,
    error,
});

export const deleteCollection = (collectionId: number) => {
    return async (
        dispatch: Dispatch<DeleteCollectionActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(deleteCollectionRequest());
        const toastId = toast.loading("Deleting collection...");

        try {
            const response = await db.deleteCollection(collectionId);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong deleting collection");
            }

            toast.update(toastId, {
                render: "Collection deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deleteCollectionSuccess(collectionId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deleteCollectionFailure(error.message));
        }
    };
};

const createGalleryItemRequest = (): CreateGalleryItemAction => ({
    type: CREATE_GALLERY_ITEM,
});

const createGalleryItemSuccess = (
    galleryItem: GalleryItem
): CreateGalleryItemSuccessAction => ({
    type: CREATE_GALLERY_ITEM_SUCCESS,
    payload: {
        galleryItem,
    },
});

const createGalleryItemFailure = (
    error: string
): CreateGalleryItemFailureAction => ({
    type: CREATE_GALLERY_ITEM_FAILURE,
    error,
});

export const createGalleryItem = (galleryItem: CreateGalleryItem) => {
    return async (
        dispatch: Dispatch<CreateGalleryItemActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(createGalleryItemRequest());
        const toastId = toast.loading("Creating gallery item...");

        try {
            const response = await db.createGalleryItem(galleryItem);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating gallery item");
            }

            toast.update(toastId, {
                render: "Gallery item created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createGalleryItemSuccess(response.galleryItem));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createGalleryItemFailure(error.message));
        }
    };
};

const updateGalleryItemRequest = (): UpdateGalleryItemAction => ({
    type: UPDATE_GALLERY_ITEM,
});

const updateGalleryItemSuccess = (
    galleryItem: GalleryItem
): UpdateGalleryItemSuccessAction => ({
    type: UPDATE_GALLERY_ITEM_SUCCESS,
    payload: {
        galleryItem,
    },
});

const updateGalleryItemFailure = (
    error: string
): UpdateGalleryItemFailureAction => ({
    type: UPDATE_GALLERY_ITEM_FAILURE,
    error,
});

export const updateGalleryItem = (galleryItem: UpdateGalleryItem) => {
    return async (
        dispatch: Dispatch<UpdateGalleryItemActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateGalleryItemRequest());
        const toastId = toast.loading(`Saving gallery item...`);

        try {
            const response = await db.updateGalleryItem(galleryItem); // do stuff

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating gallery item");
            }

            toast.update(toastId, {
                render: "Gallery item updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateGalleryItemSuccess(response.galleryItem));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateGalleryItemFailure(error.message));
        }
    };
};

const updateGalleryItemsRequest = (): UpdateGalleryItemsAction => ({
    type: UPDATE_GALLERY_ITEMS,
});

const updateGalleryItemsSuccess = (
    galleryItems: GalleryItem[]
): UpdateGalleryItemsSuccessAction => ({
    type: UPDATE_GALLERY_ITEMS_SUCCESS,
    payload: {
        galleryItems,
    },
});

const updateGalleryItemsFailure = (
    error: string
): UpdateGalleryItemsFailureAction => ({
    type: UPDATE_GALLERY_ITEMS_FAILURE,
    error,
});

export const updateGalleryItems = (galleryItems: GalleryItem[]) => {
    return async (
        dispatch: Dispatch<UpdateGalleryItemsActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updateGalleryItemsRequest());

        const toastId = toast.loading("Saving gallery items...");

        try {
            const response = await db.updateGalleryItems(galleryItems);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong updating gallery items");
            }

            toast.update(toastId, {
                render: "Gallery items updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateGalleryItemsSuccess(response.galleryItems));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateGalleryItemsFailure(error.message));
        }
    };
};

const deleteGalleryItemRequest = (): DeleteGalleryItemAction => ({
    type: DELETE_GALLERY_ITEM,
});

const deleteGalleryItemSuccess = (
    galleryItemId: number
): DeleteGalleryItemSuccessAction => ({
    type: DELETE_GALLERY_ITEM_SUCCESS,
    payload: {
        galleryItemId,
    },
});

const deleteGalleryItemFailure = (
    error: string
): DeleteGalleryItemFailureAction => ({
    type: DELETE_GALLERY_ITEM_FAILURE,
    error,
});

export const deleteGalleryItem = (galleryItemId: number) => {
    return async (
        dispatch: Dispatch<DeleteGalleryItemActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(deleteGalleryItemRequest());
        const toastId = toast.loading("Deleting gallery item...");

        try {
            const response = await db.deleteGalleryItem(galleryItemId);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong deleting gallery item");
            }

            toast.update(toastId, {
                render: "Gallery item deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deleteGalleryItemSuccess(galleryItemId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deleteGalleryItemFailure(error.message));
        }
    };
};
