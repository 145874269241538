import React from "react";
import "./Links.css";
import { useLinks } from "../../State/Context/LinksContext";

export const Links: React.FC = () => {
    const { loading, links } = useLinks();

    if (loading) return <div>loading...</div>;

    return (
        <div className="flex flex-col items-start gap-10">
            {links.map((link) =>
                link.url ? (
                    <a
                        key={link.id}
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt={`Link to ${link.url}`}
                            className="object-contain max-h-[25vh] cursor-pointer"
                            src={link.image}
                        />
                    </a>
                ) : (
                    <img
                        alt="Link without url"
                        key={link.id}
                        className="object-contain max-h-[25vh]"
                        src={link.image}
                    />
                )
            )}
        </div>
    );
};
