import { useEffect, useState } from "react";
import "./ImagePicker.css";

export type ImagePickerProps = {
    selectedImage: File | undefined;
    setSelectedImage: React.Dispatch<React.SetStateAction<File | undefined>>;
    uploadImage?: () => Promise<void>;
};

export const ImagePicker: React.FC<ImagePickerProps> = ({
    selectedImage,
    setSelectedImage,
    uploadImage,
}) => {
    const [preview, setPreview] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!selectedImage) {
            setPreview(undefined);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result?.toString());
        };
        reader.readAsDataURL(selectedImage);
    }, [selectedImage, setPreview]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const file = e.target.files[0];
        if (!file) return;
        setSelectedImage(file);
    };

    const handleImageUpload = async () => {
        if (selectedImage && uploadImage) {
            await uploadImage();
        }
    };

    return (
        <div className="flex flex-col items-center gap-4">
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {preview && (
                <div className="preview">
                    <img
                        className="max-w-full max-h-80 border-[#ddd] border-2 rounded-md p-1"
                        src={preview}
                        alt="preview"
                    />
                </div>
            )}
            {uploadImage && (
                <button
                    className="px-3 py-2 bg-site-accent text-white border-none rounded-md cursor-pointer hover:bg-[#233616]"
                    onClick={handleImageUpload}
                >
                    Save
                </button>
            )}
        </div>
    );
};
