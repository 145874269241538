import { Dispatch } from "redux";
import { SettingsItem } from "../../Types/SettingsItem";
import {
    GET_SETTINGS,
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,
    GetSettingsAction,
    GetSettingsActionTypes,
    GetSettingsFailureAction,
    GetSettingsSuccessAction,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_FAILURE,
    UPDATE_SETTINGS_SUCCESS,
    UpdateSettingAction,
    UpdateSettingActionTypes,
    UpdateSettingFailureAction,
    UpdateSettingSuccessAction,
} from "./ActionTypes/Settings";
import * as db from "../Database/settings";
import { toast } from "react-toastify";
import { SettingsState } from "../Reducers/SettingsReducer";

type GetSettingsResponse = {
    settings: SettingsItem[];
};

const getSettingsRequest = (): GetSettingsAction => ({
    type: GET_SETTINGS,
});

const getSettingsSuccess = (
    payload: GetSettingsResponse
): GetSettingsSuccessAction => ({
    type: GET_SETTINGS_SUCCESS,
    payload,
});

const getSettingsFailure = (error: string): GetSettingsFailureAction => ({
    type: GET_SETTINGS_FAILURE,
    error,
});

export const getAllSettings = () => {
    return async (
        dispatch: Dispatch<GetSettingsActionTypes>,
        getState: () => SettingsState
    ) => {
        dispatch(getSettingsRequest());

        try {
            const response = await db.getSettings();
            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong getting settings");
            }

            const data: GetSettingsResponse = {
                settings: response.settings,
            };

            dispatch(getSettingsSuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getSettingsFailure(error.message));
        }
    };
};

const updateSettingRequest = (): UpdateSettingAction => ({
    type: UPDATE_SETTINGS,
});

const updateSettingSuccess = (
    setting: SettingsItem
): UpdateSettingSuccessAction => ({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: {
        setting,
    },
});

const updateSettingFailure = (error: string): UpdateSettingFailureAction => ({
    type: UPDATE_SETTINGS_FAILURE,
    error,
});

export const updateSetting = (key: string, value: string) => {
    return async (
        dispatch: Dispatch<UpdateSettingActionTypes>,
        getState: () => SettingsState
    ) => {
        dispatch(updateSettingRequest());
        const toastId = toast.loading(`Saving setting...`);

        try {
            const response = await db.updateSettings(key, value);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating setting");
            }

            toast.update(toastId, {
                render: "Setting updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateSettingSuccess(response.setting));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateSettingFailure(error.message));
        }
    };
};
