import { CreateLinkItem, LinkItem, UpdateLinkItem } from "../../Types/LinkItem";
import supabase from "./client";
import { uploadImage } from "./images";

const LINKS_TABLE = "Links";

export type GetLinksType = {
    links: LinkItem[];
    error?: string;
};

export const getLinks = async (): Promise<GetLinksType> => {
    const { data, error } = await supabase.from(LINKS_TABLE).select();

    if (error) {
        return {
            links: [],
            error: error.message,
        };
    }

    return {
        links: data.map((x) => ({
            id: x.id,
            image: x.image,
            url: x.url ?? undefined,
        })),
    };
};

export type CreateLinkItemResponse =
    | {
          linkItem: LinkItem;
          error?: undefined;
      }
    | { error: string };

export const createLink = async (
    request: CreateLinkItem
): Promise<CreateLinkItemResponse> => {
    const imageUploadResponse = await uploadImage(request.image, "links");

    if (!imageUploadResponse.success) {
        return {
            error: imageUploadResponse.error,
        };
    }

    const { data, error } = await supabase
        .from(LINKS_TABLE)
        .insert({
            ...request,
            image: imageUploadResponse.path,
        })
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        linkItem: {
            ...data,
            url: data.url ?? undefined,
        },
    };
};

export type UpdateLinksResponse =
    | {
          link: LinkItem;
          error?: undefined;
      }
    | { error: string };

export const updateLinks = async (
    request: UpdateLinkItem
): Promise<UpdateLinksResponse> => {
    const imageUploadResponse = await uploadImage(request.image, "links");

    if (!imageUploadResponse.success) {
        return {
            error: imageUploadResponse.error,
        };
    }

    const { data, error } = await supabase
        .from(LINKS_TABLE)
        .update({
            id: request.id,
            image: imageUploadResponse.path,
            url: request.url,
        })
        .eq("id", request.id)
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        link: {
            ...data,
            url: data.url ?? undefined,
        },
    };
};

export type DeleteLinkItemResponse = {
    linkItemId: number;
    error?: string;
};

export const deleteLinkItem = async (
    linkItemId: number
): Promise<DeleteLinkItemResponse> => {
    const { error } = await supabase
        .from(LINKS_TABLE)
        .delete()
        .eq("id", linkItemId);

    if (error) {
        return {
            linkItemId,
            error: error.message,
        };
    }

    return {
        linkItemId,
    };
};
