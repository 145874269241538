import { PostItem } from "../../../Types/PostItem";

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";

// Get all

export interface GetPostsAction {
    type: typeof GET_POSTS;
}

export interface GetPostsSuccessAction {
    type: typeof GET_POSTS_SUCCESS;
    payload: {
        posts: PostItem[];
    };
}

export interface GetPostsFailureAction {
    type: typeof GET_POSTS_FAILURE;
    error: string;
}

export type GetPostsActionTypes =
    | GetPostsAction
    | GetPostsSuccessAction
    | GetPostsFailureAction;

// Create post

export const CREATE_POST = "CREATE_POST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";

export interface CreatePostAction {
    type: typeof CREATE_POST;
}

export interface CreatePostSuccessAction {
    type: typeof CREATE_POST_SUCCESS;
    payload: {
        post: PostItem;
    };
}

export interface CreatePostFailureAction {
    type: typeof CREATE_POST_FAILURE;
    error: string;
}

export type CreatePostActionTypes =
    | CreatePostAction
    | CreatePostSuccessAction
    | CreatePostFailureAction;

// Update post

export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

export interface UpdatePostAction {
    type: typeof UPDATE_POST;
}

export interface UpdatePostSuccessAction {
    type: typeof UPDATE_POST_SUCCESS;
    payload: {
        post: PostItem;
    };
}

export interface UpdatePostFailureAction {
    type: typeof UPDATE_POST_FAILURE;
    error: string;
}

export type UpdatePostActionTypes =
    | UpdatePostAction
    | UpdatePostSuccessAction
    | UpdatePostFailureAction;

// Delete post
export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export interface DeletePostAction {
    type: typeof DELETE_POST;
}

export interface DeletePostSuccessAction {
    type: typeof DELETE_POST_SUCCESS;
    payload: {
        postId: number;
    };
}

export interface DeletePostFailureAction {
    type: typeof DELETE_POST_FAILURE;
    error: string;
}

export type DeletePostActionTypes =
    | DeletePostAction
    | DeletePostSuccessAction
    | DeletePostFailureAction;

export type PostsActionTypes =
    | GetPostsActionTypes
    | CreatePostActionTypes
    | UpdatePostActionTypes
    | DeletePostActionTypes;
