import { useSettings } from "../../../State/Context/SettingsContext";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSetting } from "../../../State/Actions/SettingsActions";

export const SettingsManager: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { loading, isFancyBioEnabled } = useSettings();

    const [isOn, setIsOn] = useState(false);
    const [isLoading, setIsLoading] = useState(loading);

    useEffect(() => {
        setIsLoading(loading);
    }, [setIsLoading, loading]);

    useEffect(() => {
        setIsLoading(false);
        setIsOn(isFancyBioEnabled);
    }, [setIsOn, setIsLoading, isFancyBioEnabled]);

    const toggle = () => {
        setIsLoading(true);
        dispatch(
            updateSetting(
                "isFancyBioEnabled",
                isFancyBioEnabled ? "false" : "true"
            )
        );
    };

    return (
        <div className="flex flex-row flex-wrap gap-10">
            <p>Fancy bio</p>

            {isLoading ? (
                <p>loading...</p>
            ) : (
                <div
                    onClick={toggle}
                    className={`flex items-center cursor-pointer w-14 h-8 p-1 rounded-full transition-colors duration-300 ${
                        isOn ? "bg-green-500" : "bg-gray-300"
                    }`}
                >
                    <div
                        className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${
                            isOn ? "translate-x-6" : ""
                        }`}
                    ></div>
                </div>
            )}
        </div>
    );
};
