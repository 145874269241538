import { PostItem } from "../../Types/PostItem";
import {
    CREATE_POST_SUCCESS,
    DELETE_POST_SUCCESS,
    GET_POSTS,
    GET_POSTS_FAILURE,
    GET_POSTS_SUCCESS,
    PostsActionTypes,
    UPDATE_POST_SUCCESS,
} from "../Actions/ActionTypes/Posts";

export type PostsState = {
    posts: PostItem[];
    loading: boolean;
    error?: string;
};

const initialState: PostsState = {
    posts: [],
    loading: false,
    error: undefined,
};

const postsReducer = (
    state = initialState,
    action: PostsActionTypes
): PostsState => {
    switch (action.type) {
        case GET_POSTS:
            return {
                ...state,
                loading: true,
            };

        case GET_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                posts: action.payload.posts,
            };

        case GET_POSTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CREATE_POST_SUCCESS:
            return {
                ...state,
                posts: [...state.posts, action.payload.post],
            };

        case UPDATE_POST_SUCCESS:
            return {
                ...state,
                posts: [
                    ...state.posts.filter(
                        (x) => x.id !== action.payload.post.id
                    ),
                    action.payload.post,
                ],
            };

        case DELETE_POST_SUCCESS:
            return {
                ...state,
                posts: [
                    ...state.posts.filter(
                        (x) => x.id !== action.payload.postId
                    ),
                ],
            };

        default:
            return {
                ...state,
            };
    }
};

export default postsReducer;
