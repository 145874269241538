import supabase from "./client";
import { CreateExhibition, ExhibitionItem } from "../../Types/ExhibitionItem";

const EXHIBITIONS_TABLE = "Exhibitions";

export type GetExhibitionsType = {
    exhibitions: ExhibitionItem[];
    error?: string;
};

export const getExhibitions = async (): Promise<GetExhibitionsType> => {
    const { data, error } = await supabase.from(EXHIBITIONS_TABLE).select();

    if (error) {
        return {
            exhibitions: [],
            error: error.message,
        };
    }

    return {
        exhibitions: data.map((x) => ({
            id: x.id,
            exhibitionTypeId: x.exhibitionTypeId,
            time: x.time,
            title: x.title,
            description: x.description ?? undefined,
            order: x.order
        })),
    };
};

export type CreateExhibitionReponse =
    | {
          exhibition: ExhibitionItem;
          error?: undefined;
      }
    | { error: string };

export const createExhibition = async (
    request: CreateExhibition
): Promise<CreateExhibitionReponse> => {
    const { data, error } = await supabase
        .from(EXHIBITIONS_TABLE)
        .insert({
            ...request,
        })
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        exhibition: {
            id: data.id,
            exhibitionTypeId: data.exhibitionTypeId,
            title: data.title,
            description: data.description ?? undefined,
            time: data.time,
            order: data.order
        },
    };
};

export type UpdateExhibitionResponse = {
    exhibition: ExhibitionItem;
    error?: string;
};

export const updateExhibition = async (
    exhibition: ExhibitionItem
): Promise<UpdateExhibitionResponse> => {
    const { error } = await supabase
        .from(EXHIBITIONS_TABLE)
        .update({
            title: exhibition.title,
            description: exhibition.description,
            exhibitionTypeId: exhibition.exhibitionTypeId,
            time: exhibition.time
        })
        .eq("id", exhibition.id);
    if (error) {
        return {
            exhibition,
            error: error.message,
        };
    }

    return {
        exhibition,
    };
};

export type UpdateExhibitionsResponse = {
    exhibitions: ExhibitionItem[];
    error?: string;
};

export const updateExhibitions = async (
    exhibitions: ExhibitionItem[]
): Promise<UpdateExhibitionsResponse> => {
    let updatedExhibitions: ExhibitionItem[] = [];

    let errorMessage = "";

    exhibitions.forEach(async (exhibition) => {
        const { data, error } = await supabase
            .from(EXHIBITIONS_TABLE)
            .update({
                order: exhibition.order,
            })
            .eq("id", exhibition.id)
            .select()
            .single();

        if (error) {
            errorMessage += error.message;
        } else {
            updatedExhibitions = [
                ...updatedExhibitions,
                {
                    id: data.id,
                    exhibitionTypeId: data.exhibitionTypeId,
                    time: data.time,
                    title: data.title,
                    description: data.description ?? undefined,
                    order: data.order,
                },
            ];
        }
    });

    return {
        exhibitions,
        error: errorMessage || undefined,
    };
};

export type DeleteExhibitionResponse = {
    exhibitionId: number;
    error?: string;
};

export const deleteExhibition = async (
    exhibitionId: number
): Promise<DeleteExhibitionResponse> => {
    const { error } = await supabase
        .from(EXHIBITIONS_TABLE)
        .delete()
        .eq("id", exhibitionId);

    if (error) {
        return {
            exhibitionId,
            error: error.message,
        };
    }

    return {
        exhibitionId,
    };
};
