import { ExhibitionItem } from "../../../Types/ExhibitionItem";

export const GET_EXHIBITIONS = "GET_EXHIBITIONS";
export const GET_EXHIBITIONS_SUCCESS = "GET_EXHIBITIONS_SUCCESS";
export const GET_EXHIBITIONS_FAILURE = "GET_EXHIBITIONS_FAILURE";

export interface GetExhibitionsAction {
    type: typeof GET_EXHIBITIONS;
}

export interface GetExhibitionsSuccessAction {
    type: typeof GET_EXHIBITIONS_SUCCESS;
    payload: {
        exhibitions: ExhibitionItem[];
    };
}

export interface GetExhibitionsFailureAction {
    type: typeof GET_EXHIBITIONS_FAILURE;
    error: string;
}

export type GetExhibitionsActionTypes =
    | GetExhibitionsAction
    | GetExhibitionsSuccessAction
    | GetExhibitionsFailureAction;

// Create exhibition

export const CREATE_EXHIBITION = "CREATE_EXHIBITION";
export const CREATE_EXHIBITION_SUCCESS = "CREATE_EXHIBITION_SUCCESS";
export const CREATE_EXHIBITION_FAILURE = "CREATE_EXHIBITION_FAILURE";

export interface CreateExhibitionAction {
    type: typeof CREATE_EXHIBITION;
}

export interface CreateExhibitionSuccessAction {
    type: typeof CREATE_EXHIBITION_SUCCESS;
    payload: {
        exhibition: ExhibitionItem;
    };
}

export interface CreateExhibitionFailureAction {
    type: typeof CREATE_EXHIBITION_FAILURE;
    error: string;
}

export type CreateExhibitionActionTypes =
    | CreateExhibitionAction
    | CreateExhibitionSuccessAction
    | CreateExhibitionFailureAction;

// Update exhibition

export const UPDATE_EXHIBITION = "UPDATE_EXHIBITION";
export const UPDATE_EXHIBITION_SUCCESS = "UPDATE_EXHIBITION_SUCCESS";
export const UPDATE_EXHIBITION_FAILURE = "UPDATE_EXHIBITION_FAILURE";

export interface UpdateExhibitionAction {
    type: typeof UPDATE_EXHIBITION;
}

export interface UpdateExhibitionSuccessAction {
    type: typeof UPDATE_EXHIBITION_SUCCESS;
    payload: {
        exhibition: ExhibitionItem;
    };
}

export interface UpdateExhibitionFailureAction {
    type: typeof UPDATE_EXHIBITION_FAILURE;
    error: string;
}

export type UpdateExhibitionActionTypes =
    | UpdateExhibitionAction
    | UpdateExhibitionSuccessAction
    | UpdateExhibitionFailureAction;

// Update exhibitions

export const UPDATE_EXHIBITIONS = "UPDATE_EXHIBITIONS";
export const UPDATE_EXHIBITIONS_SUCCESS = "UPDATE_EXHIBITIONS_SUCCESS";
export const UPDATE_EXHIBITIONS_FAILURE = "UPDATE_EXHIBITIONS_FAILURE";

export interface UpdateExhibitionsAction {
    type: typeof UPDATE_EXHIBITIONS;
}

export interface UpdateExhibitionsSuccessAction {
    type: typeof UPDATE_EXHIBITIONS_SUCCESS;
    payload: {
        exhibitions: ExhibitionItem[];
    };
}

export interface UpdateExhibitionsFailureAction {
    type: typeof UPDATE_EXHIBITIONS_FAILURE;
    error: string;
}

export type UpdateExhibitionsActionTypes =
    | UpdateExhibitionsAction
    | UpdateExhibitionsSuccessAction
    | UpdateExhibitionsFailureAction;

// Delete exhibition

export const DELETE_EXHIBITION = "DELETE_EXHIBITION";
export const DELETE_EXHIBITION_SUCCESS = "DELETE_EXHIBITION_SUCCESS";
export const DELETE_EXHIBITION_FAILURE = "DELETE_EXHIBITION_FAILURE";

export interface DeleteExhibitionAction {
    type: typeof DELETE_EXHIBITION;
}

export interface DeleteExhibitionSuccessAction {
    type: typeof DELETE_EXHIBITION_SUCCESS;
    payload: {
        exhibitionId: number;
    };
}

export interface DeleteExhibitionFailureAction {
    type: typeof DELETE_EXHIBITION_FAILURE;
    error: string;
}

export type DeleteExhibitionActionTypes =
    | DeleteExhibitionAction
    | DeleteExhibitionSuccessAction
    | DeleteExhibitionFailureAction;

export type ExhibitionActionTypes =
    | GetExhibitionsActionTypes
    | CreateExhibitionActionTypes
    | UpdateExhibitionActionTypes
    | UpdateExhibitionsActionTypes
    | DeleteExhibitionActionTypes;
