import React, { useCallback } from "react";
import { Grouping } from "../../Components/Grouping/Grouping";
import "./Gallery.css";
import { useNavigate } from "react-router-dom";
import { useGallery } from "../../State/Context/GalleryContext";
import { useSettings } from "../../State/Context/SettingsContext";

type GroupBy = "category" | "collection";

export const Gallery: React.FC = () => {
    const { galleryPreviewCount } = useSettings();
    const navigate = useNavigate();
    const {
        loading,
        categories,
        collections,
        galleryItems,
        groupBy,
        setGroupBy,
    } = useGallery();

    const navigateTo = useCallback(
        (id: number) => {
            navigate(`${groupBy}/${id}`);
        },
        [groupBy, navigate]
    );

    if (loading) {
        return <>loading...</>;
    }

    const groupingSelection = (
        <form>
            <label htmlFor="groupBy">Group by</label>
            <select
                id="groupBy"
                value={groupBy}
                className="text-start"
                onChange={(e) => setGroupBy(e.target.value as GroupBy)}
            >
                <option value="collection">collection</option>
                <option value="category">category</option>
            </select>
        </form>
    );

    if (groupBy === "category")
        return (
            <div className="flex flex-col">
                {groupingSelection}
                {categories.map((category) => {
                    const itemsForGrouping = galleryItems
                        .filter(
                            (item) => item.categoryId === category.categoryId
                        )
                        .sort((a, b) => a.categoryOrder - b.categoryOrder);

                    return (
                        <Grouping
                            groupingItem={category}
                            key={category.categoryId}
                            galleryItems={itemsForGrouping}
                            limitItemsTo={galleryPreviewCount}
                            navigateToOverview={() =>
                                navigateTo(category.categoryId)
                            }
                        />
                    );
                })}
            </div>
        );

    if (groupBy === "collection")
        return (
            <div className="flex flex-col">
                {groupingSelection}
                {collections.map((collection) => {
                    const itemsForGrouping = galleryItems
                        .filter(
                            (item) =>
                                item.collectionId === collection.collectionId
                        )
                        .sort((a, b) => a.collectionOrder - b.collectionOrder);

                    return (
                        <Grouping
                            groupingItem={collection}
                            key={collection.collectionId}
                            galleryItems={itemsForGrouping}
                            limitItemsTo={galleryPreviewCount}
                            navigateToOverview={() =>
                                navigateTo(collection.collectionId)
                            }
                        />
                    );
                })}
            </div>
        );

    return <></>;
};
