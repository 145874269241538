import { GalleryCategory } from "../../../Types/GalleryCategory";
import { GalleryItem } from "../../../Types/GalleryItem";
import { GalleryCollection } from "../../../Types/GalleryCollection";

export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";

// Get all

export interface GetGalleryAction {
    type: typeof GET_GALLERY;
}

export interface GetGallerySuccessAction {
    type: typeof GET_GALLERY_SUCCESS;
    payload: {
        gallery: GalleryItem[];
        categories: GalleryCategory[];
        collections: GalleryCollection[];
    };
}

export interface GetGalleryFailureAction {
    type: typeof GET_GALLERY_FAILURE;
    error: string;
}

export type GetGalleryActionTypes =
    | GetGalleryAction
    | GetGallerySuccessAction
    | GetGalleryFailureAction;

// Create category

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export interface CreateCategoryAction {
    type: typeof CREATE_CATEGORY;
}

export interface CreateCategorySuccessAction {
    type: typeof CREATE_CATEGORY_SUCCESS;
    payload: {
        category: GalleryCategory;
    };
}

export interface CreateCategoryFailureAction {
    type: typeof CREATE_CATEGORY_FAILURE;
    error: string;
}

export type CreateCategoryActionTypes =
    | CreateCategoryAction
    | CreateCategorySuccessAction
    | CreateCategoryFailureAction;

// Update category

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export interface UpdateCategoryAction {
    type: typeof UPDATE_CATEGORY;
}

export interface UpdateCategorySuccessAction {
    type: typeof UPDATE_CATEGORY_SUCCESS;
    payload: {
        category: GalleryCategory;
    };
}

export interface UpdateCategoryFailureAction {
    type: typeof UPDATE_CATEGORY_FAILURE;
    error: string;
}

export type UpdateCategoryActionTypes =
    | UpdateCategoryAction
    | UpdateCategorySuccessAction
    | UpdateCategoryFailureAction;

// Update categories

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS";
export const UPDATE_CATEGORIES_FAILURE = "UPDATE_CATEGORIES_FAILURE";

export interface UpdateCategoriesAction {
    type: typeof UPDATE_CATEGORIES;
}

export interface UpdateCategoriesSuccessAction {
    type: typeof UPDATE_CATEGORIES_SUCCESS;
    payload: {
        categories: GalleryCategory[];
    };
}

export interface UpdateCategoriesFailureAction {
    type: typeof UPDATE_CATEGORIES_FAILURE;
    error: string;
}

export type UpdateCategoriesActionTypes =
    | UpdateCategoriesAction
    | UpdateCategoriesSuccessAction
    | UpdateCategoriesFailureAction;

// Delete category

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export interface DeleteCategoryAction {
    type: typeof DELETE_CATEGORY;
}

export interface DeleteCategorySuccessAction {
    type: typeof DELETE_CATEGORY_SUCCESS;
    payload: {
        categoryId: number;
    };
}

export interface DeleteCategoryFailureAction {
    type: typeof DELETE_CATEGORY_FAILURE;
    error: string;
}

export type DeleteCategoryActionTypes =
    | DeleteCategoryAction
    | DeleteCategorySuccessAction
    | DeleteCategoryFailureAction;

// Create collection

export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAILURE = "CREATE_COLLECTION_FAILURE";

export interface CreateCollectionAction {
    type: typeof CREATE_COLLECTION;
}

export interface CreateCollectionSuccessAction {
    type: typeof CREATE_COLLECTION_SUCCESS;
    payload: {
        collection: GalleryCollection;
    };
}

export interface CreateCollectionFailureAction {
    type: typeof CREATE_COLLECTION_FAILURE;
    error: string;
}

export type CreateCollectionActionTypes =
    | CreateCollectionAction
    | CreateCollectionSuccessAction
    | CreateCollectionFailureAction;

// Update collection

export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAILURE = "UPDATE_COLLECTION_FAILURE";

export interface UpdateCollectionAction {
    type: typeof UPDATE_COLLECTION;
}

export interface UpdateCollectionSuccessAction {
    type: typeof UPDATE_COLLECTION_SUCCESS;
    payload: {
        collection: GalleryCollection;
    };
}

export interface UpdateCollectionFailureAction {
    type: typeof UPDATE_COLLECTION_FAILURE;
    error: string;
}

export type UpdateCollectionActionTypes =
    | UpdateCollectionAction
    | UpdateCollectionSuccessAction
    | UpdateCollectionFailureAction;

// Update collections

export const UPDATE_COLLECTIONS = "UPDATE_COLLECTIONS";
export const UPDATE_COLLECTIONS_SUCCESS = "UPDATE_COLLECTIONS_SUCCESS";
export const UPDATE_COLLECTIONS_FAILURE = "UPDATE_COLLECTIONS_FAILURE";

export interface UpdateCollectionsAction {
    type: typeof UPDATE_COLLECTIONS;
}

export interface UpdateCollectionsSuccessAction {
    type: typeof UPDATE_COLLECTIONS_SUCCESS;
    payload: {
        collections: GalleryCollection[];
    };
}

export interface UpdateCollectionsFailureAction {
    type: typeof UPDATE_COLLECTIONS_FAILURE;
    error: string;
}

export type UpdateCollectionsActionTypes =
    | UpdateCollectionsAction
    | UpdateCollectionsSuccessAction
    | UpdateCollectionsFailureAction;

// Delete Collection

export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";

export interface DeleteCollectionAction {
    type: typeof DELETE_COLLECTION;
}

export interface DeleteCollectionSuccessAction {
    type: typeof DELETE_COLLECTION_SUCCESS;
    payload: {
        collectionId: number;
    };
}

export interface DeleteCollectionFailureAction {
    type: typeof DELETE_COLLECTION_FAILURE;
    error: string;
}

export type DeleteCollectionActionTypes =
    | DeleteCollectionAction
    | DeleteCollectionSuccessAction
    | DeleteCollectionFailureAction;

export const CREATE_GALLERY_ITEM = "CREATE_GALLERY_ITEM";
export const CREATE_GALLERY_ITEM_SUCCESS = "CREATE_GALLERY_ITEM_SUCCESS";
export const CREATE_GALLERY_ITEM_FAILURE = "CREATE_GALLERY_ITEM_FAILURE";

export interface CreateGalleryItemAction {
    type: typeof CREATE_GALLERY_ITEM;
}

export interface CreateGalleryItemSuccessAction {
    type: typeof CREATE_GALLERY_ITEM_SUCCESS;
    payload: {
        galleryItem: GalleryItem;
    };
}

export interface CreateGalleryItemFailureAction {
    type: typeof CREATE_GALLERY_ITEM_FAILURE;
    error: string;
}

export type CreateGalleryItemActionTypes =
    | CreateGalleryItemAction
    | CreateGalleryItemSuccessAction
    | CreateGalleryItemFailureAction;

export const UPDATE_GALLERY_ITEM = "UPDATE_GALLERY_ITEM";
export const UPDATE_GALLERY_ITEM_SUCCESS = "UPDATE_GALLERY_ITEM_SUCCESS";
export const UPDATE_GALLERY_ITEM_FAILURE = "UPDATE_GALLERY_ITEM_FAILURE";

export interface UpdateGalleryItemAction {
    type: typeof UPDATE_GALLERY_ITEM;
}

export interface UpdateGalleryItemSuccessAction {
    type: typeof UPDATE_GALLERY_ITEM_SUCCESS;
    payload: {
        galleryItem: GalleryItem;
    };
}

export interface UpdateGalleryItemFailureAction {
    type: typeof UPDATE_GALLERY_ITEM_FAILURE;
    error: string;
}

export type UpdateGalleryItemActionTypes =
    | UpdateGalleryItemAction
    | UpdateGalleryItemSuccessAction
    | UpdateGalleryItemFailureAction;

// Update gallery items

export const UPDATE_GALLERY_ITEMS = "UPDATE_GALLERY_ITEMS";
export const UPDATE_GALLERY_ITEMS_SUCCESS = "UPDATE_GALLERY_ITEMS_SUCCESS";
export const UPDATE_GALLERY_ITEMS_FAILURE = "UPDATE_GALLERY_ITEMS_FAILURE";

export interface UpdateGalleryItemsAction {
    type: typeof UPDATE_GALLERY_ITEMS;
}

export interface UpdateGalleryItemsSuccessAction {
    type: typeof UPDATE_GALLERY_ITEMS_SUCCESS;
    payload: {
        galleryItems: GalleryItem[];
    };
}

export interface UpdateGalleryItemsFailureAction {
    type: typeof UPDATE_GALLERY_ITEMS_FAILURE;
    error: string;
}

export type UpdateGalleryItemsActionTypes =
    | UpdateGalleryItemsAction
    | UpdateGalleryItemsSuccessAction
    | UpdateGalleryItemsFailureAction;

export const DELETE_GALLERY_ITEM = "DELETE_GALLERY_ITEM";
export const DELETE_GALLERY_ITEM_SUCCESS = "DELETE_GALLERY_ITEM_SUCCESS";
export const DELETE_GALLERY_ITEM_FAILURE = "DELETE_GALLERY_ITEM_FAILURE";

export interface DeleteGalleryItemAction {
    type: typeof DELETE_GALLERY_ITEM;
}

export interface DeleteGalleryItemSuccessAction {
    type: typeof DELETE_GALLERY_ITEM_SUCCESS;
    payload: {
        galleryItemId: number;
    };
}

export interface DeleteGalleryItemFailureAction {
    type: typeof DELETE_GALLERY_ITEM_FAILURE;
    error: string;
}

export type DeleteGalleryItemActionTypes =
    | DeleteGalleryItemAction
    | DeleteGalleryItemSuccessAction
    | DeleteGalleryItemFailureAction;

export type GalleryActionTypes =
    // Gallery
    | GetGalleryActionTypes
    // GalleryItem
    | CreateGalleryItemActionTypes
    | UpdateGalleryItemActionTypes
    | DeleteGalleryItemActionTypes
    // GalleryItems
    | UpdateGalleryItemsActionTypes
    // Category
    | CreateCategoryActionTypes
    | UpdateCategoryActionTypes
    | DeleteCategoryActionTypes
    // Categories
    | UpdateCategoriesActionTypes
    // Collection
    | CreateCollectionActionTypes
    | UpdateCollectionActionTypes
    | DeleteCollectionActionTypes
    // Collections
    | UpdateCollectionsActionTypes;
