import { Dispatch } from "redux";
import {
    GET_SITE_CONTENT,
    GET_SITE_CONTENT_FAILURE,
    GET_SITE_CONTENT_SUCCESS,
    GetSiteContentAction,
    GetSiteContentFailureAction,
    GetSiteContentSuccessAction,
    GetSiteContentActionTypes,
    UpdateSiteContentActionTypes,
    UpdateSiteContentAction,
    UPDATE_SITE_CONTENT,
    UPDATE_SITE_CONTENT_FAILURE,
    UpdateSiteContentFailureAction,
    UpdateSiteContentSuccessAction,
    UPDATE_SITE_CONTENT_SUCCESS,
} from "./ActionTypes/SiteContent";
import { SiteContentState } from "../Reducers/SiteContentReducer";
import { getSiteContentImages, setHeroImage } from "../Database/sitecontent";
import { toast } from "react-toastify";

type GetSiteContentResponse = {
    images: string[];
};

const getSiteContentRequest = (): GetSiteContentAction => ({
    type: GET_SITE_CONTENT,
});

const getSiteContentSuccess = (
    payload: GetSiteContentResponse
): GetSiteContentSuccessAction => ({
    type: GET_SITE_CONTENT_SUCCESS,
    payload,
});

const getSiteContentFailure = (error: string): GetSiteContentFailureAction => ({
    type: GET_SITE_CONTENT_FAILURE,
    error,
});

export const getSiteContent = () => {
    return async (
        dispatch: Dispatch<GetSiteContentActionTypes>,
        getState: () => SiteContentState
    ) => {
        dispatch(getSiteContentRequest());

        try {
            const response = await getSiteContentImages();
            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong getting site content");
            }

            const data: GetSiteContentResponse = {
                images: response.images,
            };

            dispatch(getSiteContentSuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getSiteContentFailure(error.message));
        }
    };
};

type SetSiteContentResponse = {
    newImageUrl: string;
    page: string;
};

const updateSiteContentRequest = (page: string): UpdateSiteContentAction => ({
    type: UPDATE_SITE_CONTENT,
    payload: {
        page,
    },
});

const updateSiteContentSuccess = ({
    newImageUrl,
    page,
}: SetSiteContentResponse): UpdateSiteContentSuccessAction => ({
    type: UPDATE_SITE_CONTENT_SUCCESS,
    payload: {
        page,
        newImageUrl,
    },
});

const updateSiteContentFailure = (
    error: string,
    page: string
): UpdateSiteContentFailureAction => ({
    type: UPDATE_SITE_CONTENT_FAILURE,
    payload: {
        page,
        error,
    },
});

export const updateImage = (image: File, page: string) => {
    return async (
        dispatch: Dispatch<UpdateSiteContentActionTypes>,
        getState: () => SiteContentState
    ) => {
        dispatch(updateSiteContentRequest(page));

        const toastId = toast.loading(`Saving ${page} image..`);
        try {
            const response = await setHeroImage(image, page);

            if (!response.success) {
                throw new Error("Something went wrong setting image");
            }

            const data: SetSiteContentResponse = {
                newImageUrl: response.path,
                page,
            };

            toast.update(toastId, {
                render: "New image saved!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateSiteContentSuccess(data));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateSiteContentFailure(error.message, page));
        }
    };
};
