import React, { useState } from "react";
import { useGallery } from "../../../State/Context/GalleryContext";
import { CollectionForm } from "./../Forms/CollectionForm";
import { useDispatch } from "react-redux";
import { Draggable } from "../../../Components/Draggable/Draggable";
import { GalleryCollection } from "../../../Types/GalleryCollection";
import { updateCollections } from "../../../State/Actions/GalleryActions";

export const CollectionManager: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { collections } = useGallery();

    const [saveOrder, setSaveOrder] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);

    const callback = (items: GalleryCollection[]) => {
        setDragEnabled(false);

        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index,
        }));

        dispatch(updateCollections(updatedItems));
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-x-5">
                <label htmlFor="collection_drag">
                    <input
                        checked={dragEnabled}
                        onChange={(e) => setDragEnabled(e.target.checked)}
                        className="px-5"
                        type="checkbox"
                        id="collection_drag"
                    />
                    &nbsp;Reorder
                </label>
                <button
                    className={`${!dragEnabled && "hidden"}`}
                    onClick={() => setSaveOrder(true)}
                >
                    Volgorde opslaan
                </button>
            </div>
            <Draggable
                dragEnabled={dragEnabled}
                triggerCallback={saveOrder}
                setTriggerCallback={setSaveOrder}
                callback={callback}
                items={collections}
                renderItem={(collection, i) => (
                    <CollectionForm
                        readonly={dragEnabled}
                        collection={collection}
                        key={collection.collectionId}
                    />
                )}
            />
            <CollectionForm readonly={dragEnabled} />
        </div>
    );
};
