import React from "react";
import { useGallery } from "../../../State/Context/GalleryContext";
import { GalleryForm } from "./../Forms/GalleryForm";

export const GalleryManager: React.FC = () => {
    const { galleryItems } = useGallery();

    return (
        <div className="flex flex-row flex-wrap gap-10">
            {galleryItems.map((item) => (
                <GalleryForm item={item} key={item.id} />
            ))}
            <GalleryForm />
        </div>
    );
};
