import supabase from "./client";

export type GetSiteContentType = {
    images: string[];
    error?: string;
};

export const getSiteContentImages = async (): Promise<GetSiteContentType> => {
    const { data, error } = await supabase.storage.from("sitecontent").list();

    if (error) {
        console.log(error);
        return {
            images: [],
            error: error.message,
        };
    }

    const publicUrls = await Promise.all(
        data.map((x) =>
            supabase.storage.from("sitecontent").getPublicUrl(x.name)
        )
    );

    return {
        images: publicUrls.map((x) => x.data.publicUrl),
    };
};

export const setHeroImage = async (
    image: File,
    page: string
): Promise<{success: true, path: string; } | { success: false; error?: string }> => {
    const { data: images, error: imageError } = await supabase.storage
        .from("sitecontent")
        .list();

    if (imageError) {
        return {
            success: false,
            error: imageError.message,
        };
    }

    const imageToReplace = images.find((x) => x.name.includes(page));

    if (!imageToReplace)
        return {
            success: false,
            error: "image to replace not found",
        };

    const { data, error } = await supabase.storage
        .from("sitecontent")
        .update(imageToReplace.name, image, {
            cacheControl: "3600",
            upsert: true,
        });

    if (error) {
        console.log(error);
        return {
            success: false,
            error: error.message,
        };
    }

    return {
        success: true,
        path: data.path
    };
};
