import React, { useCallback, useState } from "react";
import { GalleryCategory } from "../../Types/GalleryCategory";
import { GalleryItem } from "../../Types/GalleryItem";
import { GalleryCollection } from "../../Types/GalleryCollection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretRight,
    faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";
import "./Grouping.css";
import { useGallery } from "../../State/Context/GalleryContext";

export type GroupingProps = {
    groupingItem: GalleryCategory | GalleryCollection;
    galleryItems: GalleryItem[];
    limitItemsTo?: number;
    collapsable?: boolean;
    navigateToOverview?: () => void;
};

export const Grouping: React.FC<GroupingProps> = ({
    groupingItem,
    galleryItems,
    limitItemsTo,
    collapsable = true,
    navigateToOverview,
}: GroupingProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const { setSelectedItem } = useGallery();

    const navigateToSelectedItem = useCallback(
        (item: GalleryItem) => {
            setSelectedItem(item);
            if (navigateToOverview) {
                navigateToOverview();
            }
        },
        [setSelectedItem, navigateToOverview]
    );

    return (
        <div className="pb-3 w-full">
            <div className="flex flex-row pb-3 items-center ">
                {collapsable && (
                    <button
                        className="pe-3 py-3"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <FontAwesomeIcon
                            icon={isOpen ? faCaretDown : faCaretRight}
                        />
                    </button>
                )}
                <h2 className="font-semibold text-lg">{groupingItem.title}</h2>
                {navigateToOverview && (
                    <button
                        onClick={() => navigateToOverview()}
                        className="flex flex-row text-sm items-center viewAllContainer"
                    >
                        <p className="ps-2 viewAllText">View more</p>
                        <FontAwesomeIcon
                            className="ps-2"
                            size="2xs"
                            icon={faGreaterThan}
                        />
                    </button>
                )}
            </div>
            {isOpen && (
                <div className='flex md:flex-row flex-col md:items-baseline md:flex-wrap gap-5'>
                    {galleryItems.slice(0, limitItemsTo).map((item) => (
                        <div
                            onClick={() => navigateToSelectedItem(item)}
                            key={item.id}
                            className="select-none text-start cursor-pointer inline-block"
                        >
                            <img
                                alt={`${item.title}`}
                                className="object-contain w-full md:max-h-48 max-h-fit rounded-sm"
                                src={item.image}
                            />
                            <p className="w-0 min-w-full">{item.title}</p>
                            <p className="text-xs">{item.size}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
