import { ReactNode, createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";

interface SettingsContextType {
    loading: boolean;
    isFancyBioEnabled: boolean;
    recents: number[];
    galleryPreviewCount: number;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
    undefined
);

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const settings = useSelector((state: RootState) => state.settings.settings);
    const loading = useSelector((state: RootState) => state.settings.loading);

    const isFancyBioEnabled = () => {
        const val = settings.find((x) => x.key === "isFancyBioEnabled")?.value;

        return val === "true";
    };

    const galleryPreviewCount = () => {
        const val =
            settings.find((x) => x.key === "galleryPreviewCount")?.value ?? "0";

        return parseInt(val);
    };

    const recents = () => {
        const val = settings.find((x) => x.key === "recents")?.value ?? "";

        if (!val) return [];

        return val.split(",").map((x) => parseInt(x));
    };

    const providerContext: SettingsContextType = {
        isFancyBioEnabled: isFancyBioEnabled(),
        galleryPreviewCount: galleryPreviewCount(),
        recents: recents(),
        loading,
    };

    return (
        <SettingsContext.Provider value={providerContext}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = (): SettingsContextType => {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error("useSettings must be used within a SettingsProvider");
    }
    return context;
};
