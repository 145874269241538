import { toast } from "react-toastify";
import { CreatePost, PostItem, UpdatePost } from "../../Types/PostItem";
import * as db from "../Database/posts";
import { GalleryState } from "../Reducers/GalleryReducer";
import { PostsState } from "../Reducers/PostsReducer";
import {
    CREATE_POST,
    CREATE_POST_FAILURE,
    CREATE_POST_SUCCESS,
    CreatePostAction,
    CreatePostActionTypes,
    CreatePostFailureAction,
    CreatePostSuccessAction,
    DELETE_POST,
    DELETE_POST_FAILURE,
    DELETE_POST_SUCCESS,
    DeletePostAction,
    DeletePostActionTypes,
    DeletePostFailureAction,
    DeletePostSuccessAction,
    GET_POSTS,
    GET_POSTS_FAILURE,
    GET_POSTS_SUCCESS,
    GetPostsAction,
    GetPostsActionTypes,
    GetPostsFailureAction,
    GetPostsSuccessAction,
    UPDATE_POST,
    UPDATE_POST_FAILURE,
    UPDATE_POST_SUCCESS,
    UpdatePostAction,
    UpdatePostActionTypes,
    UpdatePostFailureAction,
    UpdatePostSuccessAction,
} from "./ActionTypes/Posts";
import { Dispatch } from "redux";

type GetPostsResponse = {
    posts: PostItem[];
};

const getPostsRequest = (): GetPostsAction => ({
    type: GET_POSTS,
});

const getPostsSuccess = (payload: GetPostsResponse): GetPostsSuccessAction => ({
    type: GET_POSTS_SUCCESS,
    payload,
});

const getPostsFailure = (error: string): GetPostsFailureAction => ({
    type: GET_POSTS_FAILURE,
    error,
});

export const getAllPosts = () => {
    return async (
        dispatch: Dispatch<GetPostsActionTypes>,
        getState: () => PostsState
    ) => {
        dispatch(getPostsRequest());

        try {
            const response = await db.getPosts();
            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong getting posts");
            }

            const data: GetPostsResponse = {
                posts: response.posts,
            };

            dispatch(getPostsSuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getPostsFailure(error.message));
        }
    };
};

const createPostRequest = (): CreatePostAction => ({
    type: CREATE_POST,
});

const createPostSuccess = (
    post: PostItem
): CreatePostSuccessAction => ({
    type: CREATE_POST_SUCCESS,
    payload: {
        post,
    },
});

const createPostFailure = (
    error: string
): CreatePostFailureAction => ({
    type: CREATE_POST_FAILURE,
    error,
});

export const createPost = (post: CreatePost) => {
    return async (
        dispatch: Dispatch<CreatePostActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(createPostRequest());
        const toastId = toast.loading("Creating post...");

        try {
            const response = await db.createPost(post);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating post");
            }

            toast.update(toastId, {
                render: "Post created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createPostSuccess(response.post));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createPostFailure(error.message));
        }
    };
};

const updatePostRequest = (): UpdatePostAction => ({
    type: UPDATE_POST,
});

const updatePostSuccess = (
    post: PostItem
): UpdatePostSuccessAction => ({
    type: UPDATE_POST_SUCCESS,
    payload: {
        post,
    },
});

const updatePostFailure = (
    error: string
): UpdatePostFailureAction => ({
    type: UPDATE_POST_FAILURE,
    error,
});

export const updatePost = (post: UpdatePost) => {
    return async (
        dispatch: Dispatch<UpdatePostActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(updatePostRequest());
        const toastId = toast.loading(`Saving post...`);

        try {
            const response = await db.updatePost(post);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating post");
            }

            toast.update(toastId, {
                render: "Post updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updatePostSuccess(response.post));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updatePostFailure(error.message));
        }
    };
};

const deletePostRequest = (): DeletePostAction => ({
    type: DELETE_POST,
});

const deletePostSuccess = (
    postId: number
): DeletePostSuccessAction => ({
    type: DELETE_POST_SUCCESS,
    payload: {
        postId,
    },
});

const deletePostFailure = (
    error: string
): DeletePostFailureAction => ({
    type: DELETE_POST_FAILURE,
    error,
});

export const deletePost = (postId: number) => {
    return async (
        dispatch: Dispatch<DeletePostActionTypes>,
        getState: () => GalleryState
    ) => {
        dispatch(deletePostRequest());
        const toastId = toast.loading("Deleting post...");

        try {
            const response = await db.deletePost(postId);

            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong deleting post");
            }

            toast.update(toastId, {
                render: "Post deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deletePostSuccess(postId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deletePostFailure(error.message));
        }
    };
};