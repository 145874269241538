import React, { useState } from "react";
import { ImagePicker } from "../../../Components/ImagePicker/ImagePicker";
import { updateImage } from "../../../State/Actions/SiteContentActions";
import { useDispatch } from "react-redux";

export const BioForm: React.FC = () => {
    const dispatch = useDispatch<any>();

    const [selectedImage, setSelectedImage] = useState<File | undefined>(
        undefined
    );

    const handleUpload = async () => {
        if (!selectedImage) return;

        dispatch(updateImage(selectedImage, "bio"));
    };

    return (
        <div className="flex flex-col gap-5">
            <h2 className="font-semibold">Bio</h2>
            <ImagePicker
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                uploadImage={handleUpload}
            />
        </div>
    );
};
