import { useEffect, useState } from "react";
import { useGallery } from "../../../State/Context/GalleryContext";
import { useDispatch } from "react-redux";
import { updateSetting } from "../../../State/Actions/SettingsActions";
import { useSettings } from "../../../State/Context/SettingsContext";
import React from "react";

export const GalleryRecentsManager: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { galleryItems } = useGallery();

    const { loading, recents: settingsRecents } = useSettings();

    const [recents, setRecents] = useState<number[]>([]);

    useEffect(() => {
        if (loading) return;

        setRecents(settingsRecents);
    }, [loading, settingsRecents]);

    const toggleChecked = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        if (event.target.checked) {
            setRecents((prev) => [...prev, id]);
        } else {
            setRecents((prev) => prev.filter((x) => x !== id));
        }
    };

    const saveRecents = () => {
        dispatch(updateSetting("recents", recents.join(",")));
    };

    return (
        <div className="flex flex-col items-start gap-y-5">
            <button onClick={() => saveRecents()}>Opslaan</button>
            <div className="grid grid-cols-2 gap-x-5 gap-y-2">
                {galleryItems.map((item) => (
                    <React.Fragment key={item.id}>
                        <div>{item.title}</div>
                        <div>
                            <label className="px-2">recent</label>
                            <input
                                checked={recents.includes(item.id)}
                                onChange={(evt) => toggleChecked(evt, item.id)}
                                id={`${item.id}_recent`}
                                type="checkbox"
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
