import { SettingsItem } from "../../../Types/SettingsItem";

export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

export interface GetSettingsAction {
    type: typeof GET_SETTINGS;
}

export interface GetSettingsSuccessAction {
    type: typeof GET_SETTINGS_SUCCESS;
    payload: {
        settings: SettingsItem[];
    };
}

export interface GetSettingsFailureAction {
    type: typeof GET_SETTINGS_FAILURE;
    error: string;
}

export type GetSettingsActionTypes =
    | GetSettingsAction
    | GetSettingsSuccessAction
    | GetSettingsFailureAction;

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

export interface UpdateSettingAction {
    type: typeof UPDATE_SETTINGS;
}

export interface UpdateSettingSuccessAction {
    type: typeof UPDATE_SETTINGS_SUCCESS;
    payload: {
        setting: SettingsItem;
    };
}

export interface UpdateSettingFailureAction {
    type: typeof UPDATE_SETTINGS_FAILURE;
    error: string;
}

export type UpdateSettingActionTypes =
    | UpdateSettingAction
    | UpdateSettingSuccessAction
    | UpdateSettingFailureAction;

export type SettingsActionTypes =
    | GetSettingsActionTypes
    | UpdateSettingActionTypes;
