import {
    CREATE_CATEGORY_SUCCESS,
    CREATE_COLLECTION_SUCCESS,
    CREATE_GALLERY_ITEM_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    DELETE_COLLECTION_SUCCESS,
    DELETE_GALLERY_ITEM_SUCCESS,
    GET_GALLERY,
    GET_GALLERY_FAILURE,
    GET_GALLERY_SUCCESS,
    GalleryActionTypes,
    UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_COLLECTIONS_SUCCESS,
    UPDATE_COLLECTION_SUCCESS,
    UPDATE_GALLERY_ITEMS_SUCCESS,
    UPDATE_GALLERY_ITEM_SUCCESS,
} from "../Actions/ActionTypes/Gallery";
import { GalleryItem } from "../../Types/GalleryItem";
import { GalleryCategory } from "../../Types/GalleryCategory";
import { GalleryCollection } from "../../Types/GalleryCollection";

export type GalleryState = {
    galleryItems: GalleryItem[];
    categories: GalleryCategory[];
    collections: GalleryCollection[];
    loading: boolean;
    error?: string;
};

const initialState: GalleryState = {
    galleryItems: [],
    categories: [],
    collections: [],
    loading: false,
    error: undefined,
};

const galleryReducer = (
    state = initialState,
    action: GalleryActionTypes
): GalleryState => {
    switch (action.type) {
        case GET_GALLERY:
            return {
                ...state,
                loading: true,
            };

        case GET_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                galleryItems: action.payload.gallery,
                categories: action.payload.categories,
                collections: action.payload.collections,
            };

        case GET_GALLERY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: [...state.categories, action.payload.category],
            };

        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: [
                    ...state.categories.filter(
                        (x) =>
                            x.categoryId !== action.payload.category.categoryId
                    ),
                    action.payload.category,
                ],
            };

        case UPDATE_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: [
                    ...state.categories.filter(
                        (x) =>
                            !action.payload.categories.find(
                                (y) => y.categoryId === x.categoryId
                            )
                    ),
                    ...action.payload.categories,
                ],
            };

        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: [
                    ...state.categories.filter(
                        (x) => x.categoryId !== action.payload.categoryId
                    ),
                ],
            };

        case CREATE_COLLECTION_SUCCESS:
            return {
                ...state,
                collections: [...state.collections, action.payload.collection],
            };

        case UPDATE_COLLECTION_SUCCESS:
            return {
                ...state,
                collections: [
                    ...state.collections.filter(
                        (x) =>
                            x.collectionId !==
                            action.payload.collection.collectionId
                    ),
                    action.payload.collection,
                ],
            };

        case UPDATE_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collections: [
                    ...state.collections.filter(
                        (x) =>
                            !action.payload.collections.find(
                                (y) => y.collectionId === x.collectionId
                            )
                    ),
                    ...action.payload.collections,
                ],
            };

        case DELETE_COLLECTION_SUCCESS:
            return {
                ...state,
                collections: [
                    ...state.collections.filter(
                        (x) => x.collectionId !== action.payload.collectionId
                    ),
                ],
            };

        case CREATE_GALLERY_ITEM_SUCCESS:
            return {
                ...state,
                galleryItems: [
                    ...state.galleryItems,
                    action.payload.galleryItem,
                ],
            };

        case UPDATE_GALLERY_ITEM_SUCCESS:
            return {
                ...state,
                galleryItems: [
                    ...state.galleryItems.filter(
                        (x) => x.id !== action.payload.galleryItem.id
                    ),
                    action.payload.galleryItem,
                ],
            };

        case UPDATE_GALLERY_ITEMS_SUCCESS:
            return {
                ...state,
                galleryItems: [
                    ...state.galleryItems.filter(
                        (x) =>
                            !action.payload.galleryItems.find(
                                (y) => y.id === x.id
                            )
                    ),
                    ...action.payload.galleryItems,
                ],
            };

        case DELETE_GALLERY_ITEM_SUCCESS:
            return {
                ...state,
                galleryItems: [
                    ...state.galleryItems.filter(
                        (x) => x.id !== action.payload.galleryItemId
                    ),
                ],
            };

        default:
            return {
                ...state,
            };
    }
};

export default galleryReducer;
