import { Dispatch } from "redux";
import { CreateLinkItem, LinkItem, UpdateLinkItem } from "../../Types/LinkItem";
import {
    CREATE_LINKS,
    CREATE_LINKS_FAILURE,
    CREATE_LINKS_SUCCESS,
    CreateLinkAction,
    CreateLinkActionTypes,
    CreateLinkFailureAction,
    CreateLinkSuccessAction,
    DELETE_LINKS,
    DELETE_LINKS_FAILURE,
    DELETE_LINKS_SUCCESS,
    DeleteLinkAction,
    DeleteLinkActionTypes,
    DeleteLinkFailureAction,
    DeleteLinkSuccessAction,
    GET_LINKS,
    GET_LINKS_FAILURE,
    GET_LINKS_SUCCESS,
    GetLinksAction,
    GetLinksActionTypes,
    GetLinksFailureAction,
    GetLinksSuccessAction,
    UPDATE_LINKS,
    UPDATE_LINKS_FAILURE,
    UPDATE_LINKS_SUCCESS,
    UpdateLinkAction,
    UpdateLinkActionTypes,
    UpdateLinkFailureAction,
    UpdateLinkSuccessAction,
} from "./ActionTypes/Links";
import * as db from "../Database/links";
import { toast } from "react-toastify";
import { LinksState } from "../Reducers/LinksReducer";

type GetLinksResponse = {
    links: LinkItem[];
};

const getLinksRequest = (): GetLinksAction => ({
    type: GET_LINKS,
});

const getLinksSuccess = (payload: GetLinksResponse): GetLinksSuccessAction => ({
    type: GET_LINKS_SUCCESS,
    payload,
});

const getLinksFailure = (error: string): GetLinksFailureAction => ({
    type: GET_LINKS_FAILURE,
    error,
});

export const getAllLinks = () => {
    return async (
        dispatch: Dispatch<GetLinksActionTypes>,
        getState: () => LinksState
    ) => {
        dispatch(getLinksRequest());

        try {
            const response = await db.getLinks();
            if (response.error) {
                console.log(response.error);
                throw new Error("Something went wrong getting links");
            }

            const data: GetLinksResponse = {
                links: response.links,
            };

            dispatch(getLinksSuccess(data));
        } catch (error: any) {
            console.log(error.message);
            dispatch(getLinksFailure(error.message));
        }
    };
};

const createLinkRequest = (): CreateLinkAction => ({
    type: CREATE_LINKS,
});

const createLinkSuccess = (link: LinkItem): CreateLinkSuccessAction => ({
    type: CREATE_LINKS_SUCCESS,
    payload: {
        link,
    },
});

const createLinkFailure = (error: string): CreateLinkFailureAction => ({
    type: CREATE_LINKS_FAILURE,
    error,
});

const updateLinkRequest = (): UpdateLinkAction => ({
    type: UPDATE_LINKS,
});

const updateLinkSuccess = (link: LinkItem): UpdateLinkSuccessAction => ({
    type: UPDATE_LINKS_SUCCESS,
    payload: {
        link,
    },
});

const updateLinkFailure = (error: string): UpdateLinkFailureAction => ({
    type: UPDATE_LINKS_FAILURE,
    error,
});

const deleteLinkRequest = (): DeleteLinkAction => ({
    type: DELETE_LINKS,
});

const deleteLinkSuccess = (linkId: number): DeleteLinkSuccessAction => ({
    type: DELETE_LINKS_SUCCESS,
    payload: {
        linkId,
    },
});

const deleteLinkFailure = (error: string): DeleteLinkFailureAction => ({
    type: DELETE_LINKS_FAILURE,
    error,
});

export const createLinkItem = (link: CreateLinkItem) => {
    return async (
        dispatch: Dispatch<CreateLinkActionTypes>,
        getState: () => LinksState
    ) => {
        dispatch(createLinkRequest());
        const toastId = toast.loading("Saving link...");

        try {
            const response = await db.createLink(link);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong creating link");
            }

            toast.update(toastId, {
                render: "Link created!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(createLinkSuccess(response.linkItem));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(createLinkFailure(error.message));
        }
    };
};

export const updateLink = (link: UpdateLinkItem) => {
    return async (
        dispatch: Dispatch<UpdateLinkActionTypes>,
        getState: () => LinksState
    ) => {
        dispatch(updateLinkRequest());
        const toastId = toast.loading(`Saving link...`);

        try {
            const response = await db.updateLinks(link);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong updating link");
            }

            toast.update(toastId, {
                render: "Link updated!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(updateLinkSuccess(response.link));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(updateLinkFailure(error.message));
        }
    };
};

export const deleteLink = (linkId: number) => {
    return async (
        dispatch: Dispatch<DeleteLinkActionTypes>,
        getState: () => LinksState
    ) => {
        dispatch(deleteLinkRequest());
        const toastId = toast.loading(`Deleting link...`);

        try {
            const response = await db.deleteLinkItem(linkId);

            if (response.error !== undefined) {
                console.log(response.error);
                throw new Error("Something went wrong deleting link");
            }

            toast.update(toastId, {
                render: "Link deleted!",
                type: "success",
                isLoading: false,
                autoClose: 1500,
            });

            dispatch(deleteLinkSuccess(linkId));
        } catch (error: any) {
            toast.update(toastId, {
                render: error.message,
                type: "error",
                isLoading: false,
                autoClose: 1500,
            });
            dispatch(deleteLinkFailure(error));
        }
    };
};
