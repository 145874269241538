import { useState, useEffect } from "react";

export type ScreenSize = "sm" | "md" | "lg" | "xl";

function useScreenWidth(): { width: number; screenSize: ScreenSize } {
    const [width, setWidth] = useState(window.innerWidth);
    const [screenSize, setScreenSize] = useState(
        getScreenSize(window.innerWidth)
    );

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setScreenSize(getScreenSize(newWidth));
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function getScreenSize(width: number): ScreenSize {
        if (width >= 1280) return "xl"; // Tailwind's xl breakpoint
        if (width >= 1024) return "lg"; // Tailwind's lg breakpoint
        if (width >= 768) return "md"; // Tailwind's md breakpoint
        return "sm"; // Tailwind's sm breakpoint
    }

    return { width, screenSize };
}

export default useScreenWidth;
