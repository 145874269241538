import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePosts } from "../../State/Context/PostsContext";
import "./PostDetail.css";
import React from "react";
import { faCalendarAlt, faClock, faClose, faLocationPin, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils";

export const PostDetail: React.FC = () => {
    const { selectedPost, setSelectedPost, setShowPostDetail } = usePosts();

    const closePostDetail = () => {
        setSelectedPost(undefined);
        setShowPostDetail(false);
    }

    if (!selectedPost) return <div></div>;

    console.log(selectedPost);

    return (
        <div className="relative">
            <button className="absolute top-0 left-0 select-none p-2 hover:bg-gray-50" onClick={closePostDetail}>
                <FontAwesomeIcon size="sm" icon={faClose} />
            </button>
            <img src={selectedPost.image} alt={selectedPost.title} className="max-h-[60vh] mx-auto" />

            <div className="grid grid-cols-[auto,1fr] auto-rows-auto gap-3 items-top w-full">
                <span />
                <h2 className="font-semibold col-span-2">{selectedPost.title}</h2>
                <FontAwesomeIcon size="sm" icon={faLocationPin} />
                <span>{selectedPost.location}</span>
                <FontAwesomeIcon size="sm" icon={faCalendarAlt} />
                <span>
                    {formatDate(selectedPost.date.start)}
                    {selectedPost.date.end && ` - ${formatDate(selectedPost.date.end)}`}
                </span>

                {selectedPost.time && (
                    <>
                        <FontAwesomeIcon size="sm" icon={faClock} />
                        <span>
                            {selectedPost.time.start}
                            {selectedPost.time.end && ` - ${selectedPost.time.end}`}
                        </span>
                    </>
                )}

                {selectedPost.description && (
                    <>
                        <FontAwesomeIcon size="sm" icon={faQuestion} />
                        <span className="whitespace-pre-line">{selectedPost.description}</span>
                    </>
                )}
            </div>
        </div>
    );
};
