import { LinkItem } from "../../../Types/LinkItem";

export const GET_LINKS = "GET_LINKS";
export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
export const GET_LINKS_FAILURE = "GET_LINKS_FAILURE";

export interface GetLinksAction {
    type: typeof GET_LINKS;
}

export interface GetLinksSuccessAction {
    type: typeof GET_LINKS_SUCCESS;
    payload: {
        links: LinkItem[];
    };
}

export interface GetLinksFailureAction {
    type: typeof GET_LINKS_FAILURE;
    error: string;
}

export type GetLinksActionTypes =
    | GetLinksAction
    | GetLinksSuccessAction
    | GetLinksFailureAction;

export const CREATE_LINKS = "CREATE_LINKS";
export const CREATE_LINKS_SUCCESS = "CREATE_LINKS_SUCCESS";
export const CREATE_LINKS_FAILURE = "CREATE_LINKS_FAILURE";

export interface CreateLinkAction {
    type: typeof CREATE_LINKS;
}

export interface CreateLinkSuccessAction {
    type: typeof CREATE_LINKS_SUCCESS;
    payload: {
        link: LinkItem;
    };
}

export interface CreateLinkFailureAction {
    type: typeof CREATE_LINKS_FAILURE;
    error: string;
}

export type CreateLinkActionTypes =
    | CreateLinkAction
    | CreateLinkSuccessAction
    | CreateLinkFailureAction;

export const UPDATE_LINKS = "UPDATE_LINKS";
export const UPDATE_LINKS_SUCCESS = "UPDATE_LINKS_SUCCESS";
export const UPDATE_LINKS_FAILURE = "UPDATE_LINKS_FAILURE";

export interface UpdateLinkAction {
    type: typeof UPDATE_LINKS;
}

export interface UpdateLinkSuccessAction {
    type: typeof UPDATE_LINKS_SUCCESS;
    payload: {
        link: LinkItem;
    };
}

export interface UpdateLinkFailureAction {
    type: typeof UPDATE_LINKS_FAILURE;
    error: string;
}

export type UpdateLinkActionTypes =
    | UpdateLinkAction
    | UpdateLinkSuccessAction
    | UpdateLinkFailureAction;

export const DELETE_LINKS = "DELETE_LINKS";
export const DELETE_LINKS_SUCCESS = "DELETE_LINKS_SUCCESS";
export const DELETE_LINKS_FAILURE = "DELETE_LINKS_FAILURE";

export interface DeleteLinkAction {
    type: typeof DELETE_LINKS;
}

export interface DeleteLinkSuccessAction {
    type: typeof DELETE_LINKS_SUCCESS;
    payload: {
        linkId: number;
    };
}

export interface DeleteLinkFailureAction {
    type: typeof DELETE_LINKS_FAILURE;
    error: string;
}

export type DeleteLinkActionTypes =
    | DeleteLinkAction
    | DeleteLinkSuccessAction
    | DeleteLinkFailureAction;

export type LinksActionTypes =
    | GetLinksActionTypes
    | CreateLinkActionTypes
    | UpdateLinkActionTypes
    | DeleteLinkActionTypes;
