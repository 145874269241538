import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useMemo,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";
import { GalleryCategory } from "../../Types/GalleryCategory";
import { GalleryCollection } from "../../Types/GalleryCollection";
import { GalleryItem } from "../../Types/GalleryItem";

export type CountByCollection = {
    collectionId: number;
    count: number;
};

export type CountByCategory = {
    categoryId: number;
    count: number;
};

interface GalleryContextType {
    selectedItem: GalleryItem | undefined;
    setSelectedItem: (item: GalleryItem | undefined) => void;

    groupBy: GroupBy;
    setGroupBy: (groupBy: GroupBy) => void;

    recentGalleryItems: GalleryItem[];

    categories: GalleryCategory[];
    collections: GalleryCollection[];
    galleryItems: GalleryItem[];

    categoryCount: number;
    collectionCount: number;
    countByCategories: CountByCategory[];
    countByCollections: CountByCollection[];

    loading: boolean;
}

const GalleryContext = createContext<GalleryContextType | undefined>(undefined);

export type GroupBy = "category" | "collection";

export const GalleryProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [selectedItem, setSelectedItem] = useState<GalleryItem | undefined>(
        undefined
    );
    const [groupBy, setGroupBy] = useState<GroupBy>("category");
    const categories = useSelector((state: RootState) =>
        state.gallery.categories.sort((a, b) => a.order - b.order)
    );
    const collections = useSelector((state: RootState) =>
        state.gallery.collections.sort((a, b) => a.order - b.order)
    );
    const galleryItems = useSelector(
        (state: RootState) => state.gallery.galleryItems
    );
    const loading = useSelector((state: RootState) => state.gallery.loading);

    const recentGalleryItems = useMemo(
        (): GalleryItem[] =>
            galleryItems.slice(0, 8).sort((a, b) => {
                const aNum = parseInt(a.dateUploaded.replaceAll("-", ""));
                const bNum = parseInt(b.dateUploaded.replaceAll("-", ""));
                return bNum - aNum;
            }),
        [galleryItems]
    );

    const { countByCollections, countByCategories } = useMemo(() => {
        let collectionCounts: any = {};
        let categoryCounts: any = {};

        // Initialize the objects with counts set to 0
        collections.forEach((collection) => {
            collectionCounts[collection.collectionId] = 0;
        });

        categories.forEach((category) => {
            categoryCounts[category.categoryId] = 0;
        });

        // Count items by collectionId and categoryId
        galleryItems.forEach((item) => {
            if (
                item.collectionId &&
                collectionCounts.hasOwnProperty(item.collectionId)
            ) {
                collectionCounts[item.collectionId]++;
            }
            if (
                item.categoryId &&
                categoryCounts.hasOwnProperty(item.categoryId)
            ) {
                categoryCounts[item.categoryId]++;
            }
        });

        // Convert the counts objects to arrays of objects
        const countByCollections: CountByCollection[] = collections.map(
            (collection) => ({
                collectionId: collection.collectionId,
                count: collectionCounts[collection.collectionId] || 0,
            })
        );

        const countByCategories: CountByCategory[] = categories.map(
            (category) => ({
                categoryId: category.categoryId,
                count: categoryCounts[category.categoryId] || 0,
            })
        );

        return { countByCollections, countByCategories };
    }, [galleryItems, collections, categories]);

    const providerContext: GalleryContextType = {
        selectedItem,
        setSelectedItem,

        groupBy,
        setGroupBy,

        recentGalleryItems,

        categories,
        collections,
        galleryItems,

        categoryCount: categories.length,
        collectionCount: collections.length,
        countByCollections: countByCollections,
        countByCategories: countByCategories,

        loading,
    };

    return (
        <GalleryContext.Provider value={providerContext}>
            {children}
        </GalleryContext.Provider>
    );
};

export const useGallery = (): GalleryContextType => {
    const context = useContext(GalleryContext);
    if (!context) {
        throw new Error("useGallery must be used within a GalleryProvider");
    }
    return context;
};
