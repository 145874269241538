import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import useTimeResetCounter from "../../Hooks/UseTimeResetCounter";
import { useAdmin } from "../../State/Context/AdminContext";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const Footer: React.FC = () => {
    const { setAdminMode } = useAdmin();

    const updateCounter = useTimeResetCounter({
        activationCount: 15,
        timeout: 3000,
        callback: () => {
            setAdminMode(true);
        },
    });
    return (
        <div className="bg-gray-950 text-white p-3 flex">
            <div className="flex flex-row">
                <a
                    href="https://www.facebook.com/luc.hoekx/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon
                        size="lg"
                        icon={faFacebook}
                        className="pe-3"
                    />
                </a>
                <a
                    href="https://www.instagram.com/lux1964/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FontAwesomeIcon
                        size="lg"
                        icon={faInstagram}
                        className="px-3"
                    />
                </a>
                <a
                    href="mailto:info@luchoekx.be"
                >
                    <FontAwesomeIcon
                        size="lg"
                        icon={faEnvelope}
                        className="px-3"
                    />
                </a>
            </div>
            <div className="flex-grow text-right">
                <button
                    onClick={updateCounter}
                    className="cursor-default select-none"
                >
                    <p className="text-xs pt-3">&copy; 2024 SeeSoft IT</p>{" "}
                </button>
            </div>
        </div>
    );
};
