import { LinkItem } from "../../Types/LinkItem";
import {
    CREATE_LINKS_SUCCESS,
    DELETE_LINKS_SUCCESS,
    GET_LINKS,
    GET_LINKS_FAILURE,
    GET_LINKS_SUCCESS,
    LinksActionTypes,
    UPDATE_LINKS_SUCCESS,
} from "../Actions/ActionTypes/Links";

export type LinksState = {
    links: LinkItem[];
    loading: boolean;
    error?: string;
};

const initialState: LinksState = {
    links: [],
    loading: false,
    error: undefined,
};

const linksReducer = (
    state = initialState,
    action: LinksActionTypes
): LinksState => {
    switch (action.type) {
        case GET_LINKS:
            return {
                ...state,
                loading: true,
            };

        case GET_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                links: action.payload.links,
            };

        case GET_LINKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CREATE_LINKS_SUCCESS:
            return {
                ...state,
                links: [...state.links, action.payload.link],
            };

        case UPDATE_LINKS_SUCCESS:
            return {
                ...state,
                links: [
                    ...state.links.filter(
                        (x) => x.id !== action.payload.link.id
                    ),
                    action.payload.link,
                ],
            };

        case DELETE_LINKS_SUCCESS:
            return {
                ...state,
                links: [
                    ...state.links.filter(
                        (x) => x.id !== action.payload.linkId
                    ),
                ],
            };

        default:
            return {
                ...state,
            };
    }
};

export default linksReducer;
