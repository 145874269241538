import "./Timeline.css";

export type TimelineItemDetails = {
    title: string;
    description?: string;
    url?: string;
};

export type TimelineItem = {
    time: string;
    details: TimelineItemDetails[];
};

export type TimelineProps = {
    items: TimelineItem[];
};

export const Timeline: React.FC<TimelineProps> = ({ items }: TimelineProps) => {
    const containerClass = "flex flex-col";
    const itemContainerClass =
        "timeline-item-container relative px-5 pb-5 border-l-gray-300 border-l-2 before:bg-site-accent";
    const timeClass = "timeline-item-time font-light italic";
    const detailContainerClass = "timeline-item-detail-container p-2 last-of-type:pb-0";

    return (
        <div className={containerClass}>
            {items.map((item) => (
                <div className={itemContainerClass} key={item.time}>
                    <h2 className={timeClass}>{item.time}</h2>
                    {item.details.map((detail, index) => (
                        <div key={index} className={detailContainerClass}>
                            <h3>{detail.title}</h3>
                            <p className="font-extralight">{detail.description}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
