import { ReactNode, useMemo, useState } from "react";
import "./Collapsable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

type ComponentProps = {
    children: ReactNode;
};

export type CollapsableProps = {
    children: ReactNode;
    open?: boolean;
};

interface CollapsableSubComponents {
    Header: React.FC<ComponentProps>;
    Body: React.FC<ComponentProps>;
}

const Header: React.FC<ComponentProps> = ({ children }) => <>{children}</>;
Header.displayName = "Header";

const Body: React.FC<ComponentProps> = ({ children }) => <>{children}</>;
Body.displayName = "Body";

const getChildrenByDisplayName = (children: ReactNode, displayName: string): ReactNode =>
    React.Children.map(
        children,
        (child: any) => child.type.displayName === displayName ? child : null
    );

const Collapsable: React.FC<CollapsableProps> & CollapsableSubComponents = ({
    children,
    open,
}: CollapsableProps) => {
    const [isOpen, setIsOpen] = useState(open);

    const header = useMemo(() => getChildrenByDisplayName(children, "Header"), [children]);
    const body = useMemo(() => getChildrenByDisplayName(children, "Body"), [children]);

    return (
        <div className="flex flex-col gap-5">
            <div className="flex items-start flex-row flex-nowrap">
                <button
                    onClick={() => setIsOpen((prev) => !prev)}
                    className="pe-3"
                >
                    <FontAwesomeIcon
                        icon={isOpen ? faCaretDown : faCaretRight}
                        size="lg"
                    />
                </button>
                {header}
            </div>
            {isOpen && body}
        </div>
    );
};

Collapsable.Header = Header;
Collapsable.Body = Body;

export { Collapsable };
