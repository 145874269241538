import { SettingsItem } from "../../Types/SettingsItem";
import {
    GET_SETTINGS,
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,
    SettingsActionTypes,
    UPDATE_SETTINGS_SUCCESS,
} from "../Actions/ActionTypes/Settings";

export type SettingsState = {
    settings: SettingsItem[];
    loading: boolean;
    error?: string;
};

const initialState: SettingsState = {
    settings: [],
    loading: false,
    error: undefined,
};

const settingsReducer = (
    state = initialState,
    action: SettingsActionTypes
): SettingsState => {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                loading: true,
            };

        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload.settings,
            };

        case GET_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: [
                    ...state.settings.filter(
                        (x) => x.id !== action.payload.setting.id
                    ),
                    action.payload.setting,
                ],
            };

        default:
            return {
                ...state,
            };
    }
};


export default settingsReducer;