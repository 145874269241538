import React, { ReactElement } from "react";
import { Post } from "../../Components/Post/Post";
import { usePosts } from "../../State/Context/PostsContext";

const titleClassList = "font-semibold text-lg pb-2";
const containerClassList = "flex items-center flex-col md:grid md:grid-cols-2 lg:flex lg:flex-row lg:flex-wrap gap-y-5 gap-x-2";

export const Upcoming: React.FC = () => {
    const { loading, upcoming, ongoing, history } = usePosts();

    if (loading) {
        return <div>loading...</div>;
    }

    const separator: ReactElement = (
        <div className="border-t border border-site-accent" />
    );

    return (
        <div>
            {upcoming.length > 0 && (
                <div className="py-5">
                    <h2 className={titleClassList}>
                        upcoming
                    </h2>
                    <div className={containerClassList}>
                        {upcoming.map((post) => (
                            <Post post={post} key={post.id} />
                        ))}
                    </div>
                </div>
            )}
            {upcoming.length > 0 && ongoing.length > 0 && separator}
            {ongoing.length > 0 && (
                <div className="py-5">
                    <h2 className={titleClassList}>ongoing</h2>
                    <div className={containerClassList}>
                        {ongoing.map((post) => (
                            <Post post={post} key={post.id} />
                        ))}
                    </div>
                </div>
            )}
            {(ongoing.length > 0 || upcoming.length > 0) && history.length > 0 && separator}
            {history.length > 0 && (
                <div className="py-5">
                    <h2 className={titleClassList}>history</h2>
                    <div className={containerClassList}>
                        {history.map((post) => (
                            <Post post={post} key={post.id} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
