import React, { useState } from "react";
import { GalleryCategory } from "../../../Types/GalleryCategory";
import { useDispatch } from "react-redux";
import {
    createCategory,
    deleteCategory,
    updateCategory,
} from "../../../State/Actions/GalleryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { useGallery } from "../../../State/Context/GalleryContext";

type CategoryFormProps = {
    category?: GalleryCategory;
    readonly: boolean;
};
export const CategoryForm: React.FC<CategoryFormProps> = ({
    category,
    readonly,
}) => {
    const dispatch = useDispatch<any>();
    const [categoryId] = useState(category?.categoryId);
    const [value, setValue] = useState(category?.title ?? "");

    const { categoryCount } = useGallery();

    const update = () => {
        if (!categoryId || !value) return;

        dispatch(
            updateCategory({
                categoryId,
                title: value,
                order: category!.order,
            })
        );
    };

    const create = () => {
        if (!value) return;

        dispatch(
            createCategory({
                title: value,
                order: categoryCount,
            })
        );

        setValue("");
    };

    const deleteIt = () => {
        if (!categoryId) return;

        confirmAlert({
            title: `Delete ${value}`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deleteCategory(categoryId)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="flex flex-row gap-10">
            <input
                readOnly={readonly}
                placeholder="title"
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            ></input>
            {!categoryId && (
                <button disabled={readonly} onClick={create}>
                    Create
                </button>
            )}
            {categoryId && (
                <div className="flex flex-row gap-3">
                    <button disabled={readonly} onClick={update}>
                        Update
                    </button>
                    <button disabled={readonly} onClick={deleteIt}>
                        <FontAwesomeIcon icon={faTrash} color="darkred" />
                    </button>
                </div>
            )}
        </div>
    );
};
