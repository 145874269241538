import { useNavigate, useParams } from "react-router-dom";
import { Grouping } from "../../Components/Grouping/Grouping";
import "./Overview.css";
import React, { useCallback, useEffect, useState } from "react";
import { GalleryItem } from "../../Types/GalleryItem";
import { GalleryCategory } from "../../Types/GalleryCategory";
import { GalleryCollection } from "../../Types/GalleryCollection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { useGallery } from "../../State/Context/GalleryContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";

type RouteParams = {
    groupBy: "collection" | "category";
    id: string;
    selectedItemId?: string;
};

export const Overview: React.FC = () => {
    const navigate = useNavigate();
    const { groupBy, id } = useParams<RouteParams>();

    const {
        galleryItems,
        categories,
        collections,
        selectedItem,
        setSelectedItem,
    } = useGallery();

    const [filteredGalleryItems, setFilteredGalleryItems] = useState<
        GalleryItem[]
    >([]);
    const [groupingItem, setGroupingItem] = useState<
        GalleryCategory | GalleryCollection | undefined
    >(undefined);

    const handleBackClick = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        return () => setSelectedItem(undefined);
    }, [setSelectedItem]);

    useEffect(() => {
        let filteredItems: GalleryItem[];
        let groupingItem: GalleryCategory | GalleryCollection | undefined;

        const parsedId = parseInt(id as string);

        switch (groupBy) {
            case "collection":
                filteredItems = galleryItems.filter(
                    (item) => item.collectionId === parsedId
                );
                groupingItem = collections.find(
                    (item) => item.collectionId === parsedId
                );
                break;
            case "category":
                filteredItems = galleryItems.filter(
                    (item) => item.categoryId === parsedId
                );
                groupingItem = categories.find(
                    (item) => item.categoryId === parsedId
                );
                break;
            default:
                throw new Error(`${groupBy} is not defined`);
        }

        setFilteredGalleryItems(filteredItems);
        setGroupingItem(groupingItem);
    }, [galleryItems, setFilteredGalleryItems, setGroupingItem, categories, collections, groupBy, id]);

    const selectedItemIndex =
        selectedItem &&
        filteredGalleryItems.findIndex((x) => x.id === selectedItem.id);

    const navigateCarousel = useCallback((direction: -1 | 1) => {
        if (selectedItemIndex === undefined) return;

        let nextIndex = selectedItemIndex + direction;

        if (nextIndex === filteredGalleryItems.length) {
            nextIndex = 0;
        }

        if (nextIndex === -1) {
            nextIndex = filteredGalleryItems.length - 1;
        }

        const newSelectedItem = filteredGalleryItems[nextIndex];
        setSelectedItem(newSelectedItem);
    }, [selectedItemIndex, setSelectedItem, filteredGalleryItems]);

    if (selectedItem)
        return (
            <div>
                <div className="flex justify-end">
                    <button className="p-0 md:p-3 opacity-70 hover:opacity-100 transition-opacity" onClick={() => setSelectedItem(undefined)}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <Carousel
                    className="h-[70vh] md:h-[80vh] select-none mb-5"
                    infiniteLoop={true}
                    swipeable={false}
                    selectedItem={selectedItemIndex}
                    animationHandler={"fade"}
                    showThumbs={false}
                    showArrows={true}
                    showStatus={false}
                    showIndicators={false}
                >
                    {filteredGalleryItems.map((i) => (
                        <div key={i.id} className="h-[70vh] md:h-[80vh]">
                            <img
                                alt={`${i.title}`}
                                className="object-contain h-full"
                                src={i.image}
                            />
                            <p className="shrinking-legend">{i.title}</p>
                            <p className="size-label">{i.size}</p>
                        </div>
                    ))}
                </Carousel>
                <div className="controls flex flex-col">
                    <div className="flex justify-between">
                    <button className="px-3" onClick={() => navigateCarousel(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <button className="px-3" onClick={() => navigateCarousel(1)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="flex flex-col items-start">
            <button onClick={() => handleBackClick()}>
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </button>
            {groupingItem && (
                <Grouping
                    galleryItems={filteredGalleryItems}
                    groupingItem={groupingItem}
                    collapsable={false}
                />
            )}
        </div>
    );
};
