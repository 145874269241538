import { useLinks } from "../../../State/Context/LinksContext";
import { LinksForm } from "../Forms/LinksForm";

export const LinksManager: React.FC = () => {
    const { links } = useLinks();

    return (
        <div className="flex flex-row flex-wrap gap-10">
            {links.map((link) => (
                <LinksForm item={link} key={link.id} />
            ))}
            <LinksForm />
        </div>
    );
};