import React, {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { PostItem } from "../../Types/PostItem";
import { useSelector } from "react-redux";
import { RootState } from "../RootReducer";

interface PostsContextType {
    posts: PostItem[];
    upcoming: PostItem[];
    ongoing: PostItem[];
    history: PostItem[];
    loading: boolean;
    selectedPost: PostItem | undefined;
    setSelectedPost: React.Dispatch<React.SetStateAction<PostItem | undefined>>;
    showPostDetail: boolean;
    setShowPostDetail: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostsContext = createContext<PostsContextType | undefined>(undefined);

export const PostsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const upcomingFilterFn = useCallback((item: PostItem): boolean => {
        const current = new Date();
        current.setHours(0, 0, 0, 0);
        const itemDate = new Date(item.date.start);
        return itemDate > current;
    }, []);

    const ongoingFilterFn = useCallback((item: PostItem): boolean => {
        if (!item.date.end) {
            return false;
        }

        const current = new Date();
        current.setHours(0, 0, 0, 0);
        const itemStartDate = new Date(item.date.start);
        const itemEndDate = new Date(item.date.end);

        return current >= itemStartDate && current <= itemEndDate;
    }, []);

    const historyFilterFn = useCallback((item: PostItem): boolean => {
        return !upcomingFilterFn(item) && !ongoingFilterFn(item);
    }, [upcomingFilterFn, ongoingFilterFn]);

    const sortFn = useCallback((a: PostItem, b: PostItem): number => {
        if (a.date.start !== b.date.start) {
            const aNum = parseInt(a.date.start.replaceAll("-", ""));
            const bNum = parseInt(b.date.start.replaceAll("-", ""));
            return aNum - bNum;
        }

        return 0; // If all comparisons are equal
    }, []);

    const loading = useSelector((state: RootState) => state.posts.loading);
    const posts = useSelector((state: RootState) => state.posts.posts);

    const upcoming = useMemo(
        () => posts.filter(upcomingFilterFn).sort(sortFn),
        [posts, sortFn, upcomingFilterFn]
    );
    const ongoing = useMemo(
        () => posts.filter(ongoingFilterFn).sort(sortFn),
        [posts, sortFn, ongoingFilterFn]
    );
    const history = useMemo(
        () => posts.filter(historyFilterFn).sort(sortFn),
        [posts, sortFn, historyFilterFn]
    );

    const [selectedPost, setSelectedPost] = useState<PostItem | undefined>(undefined);
    const [showPostDetail, setShowPostDetail] = useState(false);

    const providerContext: PostsContextType = {
        posts,
        loading,
        upcoming,
        ongoing,
        history,
        selectedPost,
        setSelectedPost,
        showPostDetail,
        setShowPostDetail
    };

    return (
        <PostsContext.Provider value={providerContext}>
            {children}
        </PostsContext.Provider>
    );
};

export const usePosts = (): PostsContextType => {
    const context = useContext(PostsContext);
    if (!context) {
        throw new Error("usePosts must be used within a PostsProvider");
    }
    return context;
};
