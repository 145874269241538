import { SettingsItem } from "../../Types/SettingsItem";
import supabase from "./client";

const SETTINGS_TABLE = "Settings";

export type GetSettingsType = {
    settings: SettingsItem[];
    error?: string;
};

export const getSettings = async (): Promise<GetSettingsType> => {
    const { data, error } = await supabase.from(SETTINGS_TABLE).select();

    if (error) {
        return {
            settings: [],
            error: error.message,
        };
    }

    return {
        settings: data.map((x) => ({
            id: x.id,
            key: x.key,
            value: x.value,
        })),
    };
};

export type UpdateSettingsResponse =
    | {
          setting: SettingsItem;
          error?: undefined;
      }
    | { error: string };

export const updateSettings = async (
    key: string,
    value: string
): Promise<UpdateSettingsResponse> => {
    const { data, error } = await supabase
        .from(SETTINGS_TABLE)
        .update({
            value: value
        })
        .eq("key", key)
        .select()
        .single();

    if (error) {
        return {
            error: error.message,
        };
    }

    return {
        setting: {
            ...data,
        },
    };
};
