import { ReactNode, createContext, useContext, useState } from "react";

interface AdminContextType {
    adminMode: boolean;
    setAdminMode: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [adminMode, setAdminMode] = useState(false);

    const providerContext: AdminContextType = {
        adminMode,
        setAdminMode,
    };

    return (
        <AdminContext.Provider value={providerContext}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdmin = (): AdminContextType => {
    const context = useContext(AdminContext);
    if (!context) {
        throw new Error("useAdmin must be used within an admincontextprovider");
    }
    return context;
}
