import React, { useState } from "react";
import { GalleryCollection } from "../../../Types/GalleryCollection";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { useGallery } from "../../../State/Context/GalleryContext";
import { createCollection, deleteCollection, updateCollection } from "../../../State/Actions/GalleryActions";

type CollectionFormProps = {
    collection?: GalleryCollection;
    readonly: boolean;
};
export const CollectionForm: React.FC<CollectionFormProps> = ({
    collection,
    readonly,
}) => {
    const dispatch = useDispatch<any>();
    const [collectionId] = useState(collection?.collectionId);
    const [value, setValue] = useState(collection?.title ?? "");

    const { collectionCount } = useGallery();

    const update = () => {
        if (!collectionId || !value) return;

        dispatch(
            updateCollection({
                collectionId,
                title: value,
                order: collection!.order,
            })
        );
    };

    const create = () => {
        if (!value) return;

        dispatch(
            createCollection({
                title: value,
                order: collectionCount,
            })
        );

        setValue("");
    };

    const deleteIt = () => {
        if (!collectionId) return;

        confirmAlert({
            title: `Delete ${value}`,
            message: "Are you sure?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => dispatch(deleteCollection(collectionId)),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="flex flex-row gap-10">
            <input
                readOnly={readonly}
                placeholder="title"
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            ></input>
            {!collectionId && (
                <button disabled={readonly} onClick={create}>
                    Create
                </button>
            )}
            {collectionId && (
                <div className="flex flex-row gap-3">
                    <button disabled={readonly} onClick={update}>
                        Update
                    </button>
                    <button disabled={readonly} onClick={deleteIt}>
                        <FontAwesomeIcon icon={faTrash} color="darkred" />
                    </button>
                </div>
            )}
        </div>
    );
};
