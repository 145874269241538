import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Draggable } from "../../../Components/Draggable/Draggable";
import { ExhibitionItem } from "../../../Types/ExhibitionItem";
import { useExhibitions } from "../../../State/Context/ExhibitionsContext";
import { updateExhibitions } from "../../../State/Actions/ExhibitionsActions";

type ExhibitionsOrderSetManagerProps = {
    items: ExhibitionItem[];
    year: string;
};

const ExhibitionsOrderSetManager: React.FC<ExhibitionsOrderSetManagerProps> = ({
    items,
    year,
}) => {
    const dispatch = useDispatch<any>();

    const [saveOrder, setSaveOrder] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);

    const callback = (items: ExhibitionItem[]) => {
        setDragEnabled(false);

        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index,
        }));

        dispatch(updateExhibitions(updatedItems));
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-x-5">
                <p className="font-bold">{year}</p>
                <label htmlFor={`${year}_drag`}>
                    <input
                        checked={dragEnabled}
                        onChange={(e) => setDragEnabled(e.target.checked)}
                        className="px-5"
                        type="checkbox"
                        id={`${year}_drag`}
                    />
                    &nbsp;Reorder
                </label>
                <button
                    className={`${!dragEnabled && "hidden"}`}
                    onClick={() => setSaveOrder(true)}
                >
                    Volgorde opslaan
                </button>
            </div>
            <div className={`${!dragEnabled && "hidden"}`}>
                <Draggable
                    dragEnabled={dragEnabled}
                    triggerCallback={saveOrder}
                    setTriggerCallback={setSaveOrder}
                    callback={callback}
                    items={items}
                    renderItem={(item, i) => <div key={i}>{item.title}</div>}
                />
            </div>
        </div>
    );
};

export const ExhibitionsOrderManager: React.FC = () => {
    const { exhibitions } = useExhibitions();

    const groupedByYear = useMemo(() => {
        return exhibitions.reduce((acc: any, item) => {
            const { time } = item;
            if (!acc[time]) {
                acc[time] = [];
            }
            acc[time].push(item);
            return acc;
        }, {});
    }, [exhibitions]);

    return (
        <div className="flex flex-col gap-10">
            {Object.keys(groupedByYear)
                .sort((a, b) => b.localeCompare(a))
                .map((year, i) => (
                    <ExhibitionsOrderSetManager
                        items={groupedByYear[year] as ExhibitionItem[]}
                        key={i}
                        year={year}
                    />
                ))}
        </div>
    );
};
